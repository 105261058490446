import styled, { css, keyframes } from 'styled-components';

import { size, rgba } from '../../layout/helpers';
import { fadeIn, noTransform } from '../../layout/keyframes';
import { ButtonReset } from '../../layout/reset';
import { Wrapper as TabsWrapper, List as TabsList } from '../Tabs/wrapper';

export const animationDuration = 200;

// Needs an unique keyframe to animate out
const animateOut = keyframes`
  from {
      transform: translateX(0);
  }

  to {
      transform: translateX(100%);
  }
`;

export const Wrapper = styled.div.attrs(() => ({
  role: 'dialog',
}))`
  position: relative;
  z-index: ${(props) => props.theme.zindex.panel};
`;

interface UiBlockProps {
  $animateOut?: boolean;
}

export const UiBlock = styled.div.attrs(() => ({
  'aria-hidden': 'true',
  role: 'button',
}))<UiBlockProps>`
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: ${(props) => props.theme.color.overlay.main};
  animation: ${fadeIn} ${animationDuration}ms ease;

  @media (prefers-reduced-motion) {
    animation: none;
  }

  ${({ $animateOut }) =>
    $animateOut &&
    css`
      animation: ${fadeIn} reverse ${animationDuration}ms ease;
    `}
`;

interface PanelWrapperProps {
  $animateOut?: boolean;
}

export const PanelWrapper = styled.article<PanelWrapperProps>`
  display: flex;
  flex-direction: column;
  position: fixed;
  top: 0;
  bottom: 0;
  right: 0;
  width: calc(100% - ${size(3)});
  max-width: 520px;
  background-color: ${(props) => props.theme.color.bg.alt};
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.05);
  z-index: 1;
  transform: translateX(100%);
  animation: ${noTransform} ${animationDuration}ms ease forwards;

  @media (prefers-reduced-motion) {
    animation-duration: 0s;
  }

  ${({ $animateOut }) =>
    $animateOut &&
    css`
      animation: ${animateOut} ${animationDuration}ms ease;
    `}
`;

export const Header = styled.header`
  position: relative;
  display: flex;
  flex-shrink: 0;
  align-items: baseline;
  min-height: 45px;
  padding-left: ${size(5)};
  padding-right: ${size(5)};
  padding-top: ${size(5)};

  &:after {
    content: '';
    position: absolute;
    top: 100%;
    left: 0;
    right: 0;
    height: ${size(5)};
    background-image: linear-gradient(
      ${(props) => props.theme.color.bg.alt},
      ${(props) => rgba(props.theme.color.bg.alt, 0)}
    );
    z-index: 10;
    pointer-events: none;
  }

  ${(props) => props.theme.breakpoint.LtSm} {
    padding-left: ${size(3)};
    padding-right: ${size(3)};
    padding-top: ${size(7)};

    &:after {
      height: ${size(3)};
    }
  }
`;

interface HeadingProps {
  $truncate?: boolean;
}

export const Heading = styled.div<HeadingProps>`
  flex: 1 1 auto;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;

  ${({ $truncate }) =>
    $truncate &&
    css`
      color: currentColor;
    `}
`;

export const Close = styled.div`
  flex-shrink: 0;
  margin: ${size(-2)};
  padding-left: ${size(3)};
`;

export const CloseBtn = styled(ButtonReset)`
  position: relative;
  display: block;
  padding: ${size(2)};
  font-size: ${(props) => props.theme.font.size.large};
  transform-origin: 50% 50%;
  transition: transform 200ms ease;
  z-index: 11;

  &:before {
    content: '';
    position: absolute;
    top: 50%;
    left: 50%;
    width: ${size(5)};
    height: ${size(5)};
    border-radius: 50%;
    background-color: ${(props) => props.theme.color.bg.main};
    opacity: 0;
    transform: translate(-50%, -50%);
    transition: opacity 200ms ease;
    z-index: -1;
  }

  &:active {
    transform: scale(0.9);
  }

  @media (prefers-reduced-motion) {
    &:active {
      transform: none;
    }
  }

  &:hover {
    &:before {
      opacity: 1;
    }
  }
`;

export const Body = styled.div`
  flex: 1 1 auto;
  overflow: auto;
  -webkit-overflow-scrolling: touch;
  padding: ${size(6)} ${size(5)};

  ${(props) => props.theme.breakpoint.LtSm} {
    padding: ${size(6)} ${size(3)};
  }
`;

export const PanelTabs = styled.div`
  position: relative;
  margin-bottom: ${size(6)};

  &:before,
  &:after {
    content: '';
    position: absolute;
    top: 0;
    bottom: 0;
    width: ${size(5)};
    z-index: 1;
    pointer-events: none;
  }

  &:before {
    left: ${size(-5)};
    background-image: linear-gradient(
      90deg,
      ${(props) => props.theme.color.bg.alt},
      ${(props) => rgba(props.theme.color.bg.alt, 0)}
    );
  }

  &:after {
    right: ${size(-5)};
    background-image: linear-gradient(
      90deg,
      ${(props) => rgba(props.theme.color.bg.alt, 0)},
      ${(props) => props.theme.color.bg.alt}
    );
  }

  ${TabsWrapper} {
    margin: 0 ${size(-5)};
  }

  ${TabsList} {
    padding: 0 ${size(5)};

    &::-webkit-scrollbar-track {
      border-left: ${size(5)} solid transparent;
      border-right: ${size(5)} solid transparent;
    }

    &::-webkit-scrollbar-thumb {
      border-left: ${size(5)} solid transparent;
      border-right: ${size(5)} solid transparent;
    }
  }

  ${(props) => props.theme.breakpoint.LtSm} {
    &:before,
    &:after {
      width: ${size(3)};
    }

    &:before {
      left: ${size(-3)};
    }

    &:after {
      right: ${size(-3)};
    }

    ${TabsWrapper} {
      margin: 0 ${size(-3)};
    }

    ${TabsList} {
      padding: 0 ${size(3)};

      &::-webkit-scrollbar-track {
        border-left: ${size(3)} solid transparent;
        border-right: ${size(3)} solid transparent;
      }

      &::-webkit-scrollbar-thumb {
        border-left: ${size(3)} solid transparent;
        border-right: ${size(3)} solid transparent;
      }
    }
  }
`;

export const Footer = styled.footer`
  position: relative;
  flex-shrink: 0;
  padding: ${size(5)};
  padding-top: 0;
  padding-bottom: calc(${size(5)} + env(safe-area-inset-bottom));

  &:before {
    content: '';
    position: absolute;
    bottom: 100%;
    left: 0;
    right: 0;
    height: ${size(5)};
    background-image: linear-gradient(
      ${(props) => rgba(props.theme.color.bg.alt, 0)},
      ${(props) => props.theme.color.bg.alt}
    );
    pointer-events: none;
  }

  ${(props) => props.theme.breakpoint.LtSm} {
    padding: ${size(3)};
    padding-top: 0;
    padding-bottom: calc(${size(3)} + env(safe-area-inset-bottom));

    &:before {
      height: ${size(3)};
    }
  }
`;

export const FooterAction = styled.div.attrs(() => ({
  role: 'group',
}))`
  display: flex;
  align-items: center;
  margin: ${size(-0.5)};

  ${(props) => props.theme.breakpoint.Sm} {
    justify-content: flex-end;
  }
`;

export const FooterActionItem = styled.div`
  padding: ${size(0.5)};

  ${(props) => props.theme.breakpoint.LtSm} {
    flex: 1 1 50%;
  }
`;

export const PanelHighlightBox = styled.div`
  background-color: ${(props) => props.theme.color.bg.main};
  margin: 0 ${size(-5)};
  padding: ${size(5)};

  ${(props) => props.theme.breakpoint.LtSm} {
    margin: 0 ${size(-3)};
    padding: ${size(3)};
  }
`;

export const SubPanelWrapper = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  z-index: 20;
`;

export const SubPanelUiBlock = styled.div.attrs(() => ({
  'aria-hidden': 'true',
  role: 'button',
}))`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: ${(props) => props.theme.color.overlay.alt};
  animation: ${fadeIn} ${animationDuration}ms ease;

  @media (prefers-reduced-motion) {
    animation: none;
  }
`;

export const SubPanelDialog = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  height: calc(100% - ${size(8)});
  background-color: ${(props) => props.theme.color.bg.alt};
  transform: translateY(100%);
  animation: ${noTransform} ${animationDuration}ms ease forwards;
  z-index: 1;

  ${(props) => props.theme.breakpoint.Sm} {
    height: calc(100% - ${size(10)});
  }

  @media (prefers-reduced-motion) {
    animation-duration: 0s;
  }

  ${Header} {
    padding-top: ${size(5)};

    ${(props) => props.theme.breakpoint.LtSm} {
      padding-top: ${size(3)};
    }
  }

  ${Body} {
    padding: ${size(5)};

    ${(props) => props.theme.breakpoint.LtSm} {
      padding: ${size(3)};
    }
  }
`;
