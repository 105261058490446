import styled from 'styled-components';

export const Wrapper = styled.div.attrs(() => ({
  role: 'checkbox',
}))``;

export const Label = styled.label`
  cursor: pointer;
  text-indent: -9999px;
  width: 2.5rem;
  height: 1.5rem;
  background: ${(props) => props.theme.color.border.main};
  display: block;
  border-radius: 100px;
  position: relative;
  &:after {
    content: '';
    position: absolute;
    top: 0.125rem;
    left: 0.125rem;
    width: 1.25rem;
    height: 1.25rem;
    background: #fff;
    border-radius: 90px;
    transition: 0.3s;
  }
`;

export const Input = styled.input`
  position: fixed;
  left: -9999px;
  opacity: 0;
  height: 0;
  width: 0;

  &:focus + ${Label} {
    outline: 2px solid ${(props) => props.theme.color.border.alt};
  }
  &:checked + ${Label} {
    background: ${(props) => props.theme.color.brand.primary.main};
    &:after {
      left: calc(100% - 0.125rem);
      transform: translateX(-100%);
    }
  }

  &[disabled] {
    & + ${Label} {
      color: ${(props) => props.theme.color.disabled.text};
      cursor: not-allowed;
    }
  }
`;
