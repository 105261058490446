import { css } from 'styled-components';

export const utilityClasses = css`
  .text {
    &-small {
      font-size: ${({ theme }) => theme.font.size.small};
    }
    &-large {
      font-size: ${({ theme }) => theme.font.size.large};
    }
    &-xlarge {
      font-size: ${({ theme }) => theme.font.size.xlarge};
    }

    // Weights
    &-bold {
      font-weight: bold;
    }

    // Align
    &-center {
      text-align: center;
    }
  }

  .color {
    &-main {
      color: ${({ theme }) => theme.color.text.main};
    }
    &-alt {
      color: ${({ theme }) => theme.color.text.alt};
    }
    &-inverted {
      color: ${({ theme }) => theme.color.text.inverted};
    }
  }

  .margin {
    &-top {
      &-8 {
        margin-top: 8px;
      }
    }

    &-bottom {
      &-8 {
        margin-bottom: 8px;
      }
      &-64 {
        margin-bottom: 64px;
      }
    }

    &-top {
      &-32 {
        margin-top: 32px;
      }
    }
  }

  .no {
    &-margin {
      margin: 0;
    }
  }
`;
