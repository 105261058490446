import cloneDeep from 'lodash.clonedeep';
import { BaseAction } from '../../@types/Action';
import {
  RequestState,
  requestInit,
  requestSuccessful,
  requestError,
  requestIdle,
} from '../../http/requestState';
import { SavedFilter, Spreadsheet } from './ReportsModels';

export const REQ_GET_SPREADSHEET = 'REQ_GET_SPREADSHEET';
export const RCV_GET_SPREADSHEET = 'RCV_GET_SPREADSHEET';
export const ERR_GET_SPREADSHEET = 'ERR_GET_SPREADSHEET';

type REQ_GET_SPREADSHEET_TYPE = BaseAction<typeof REQ_GET_SPREADSHEET>;
type RCV_GET_SPREADSHEET_TYPE = BaseAction<typeof RCV_GET_SPREADSHEET> & {
  spreadsheet: Spreadsheet;
  page?: number;
};
type ERR_GET_SPREADSHEET_TYPE = BaseAction<typeof ERR_GET_SPREADSHEET>;

export const REQ_GET_SAVED_FILTERS = 'REQ_GET_SAVED_FILTERS';
export const RCV_GET_SAVED_FILTERS = 'RCV_GET_SAVED_FILTERS';
export const ERR_GET_SAVED_FILTERS = 'ERR_GET_SAVED_FILTERS';
type REQ_GET_SAVED_FILTERS_TYPE = BaseAction<typeof REQ_GET_SAVED_FILTERS>;
type RCV_GET_SAVED_FILTERS_TYPE = BaseAction<typeof RCV_GET_SAVED_FILTERS> & {
  savedFilters: SavedFilter[];
};
type ERR_GET_SAVED_FILTERS_TYPE = BaseAction<typeof ERR_GET_SAVED_FILTERS>;

export const REQ_CREATE_SAVED_FILTERS = 'REQ_CREATE_SAVED_FILTERS';
export const RCV_CREATE_SAVED_FILTERS = 'RCV_CREATE_SAVED_FILTERS';
export const ERR_CREATE_SAVED_FILTERS = 'ERR_CREATE_SAVED_FILTERS';
type REQ_CREATE_SAVED_FILTERS_TYPE = BaseAction<typeof REQ_CREATE_SAVED_FILTERS>;
type RCV_CREATE_SAVED_FILTERS_TYPE = BaseAction<typeof RCV_CREATE_SAVED_FILTERS> & {
  savedFilter: SavedFilter;
};
type ERR_CREATE_SAVED_FILTERS_TYPE = BaseAction<typeof ERR_CREATE_SAVED_FILTERS>;

export const REQ_REMOVE_SAVED_FILTER = 'REQ_REMOVE_SAVED_FILTER';
export const RCV_REMOVE_SAVED_FILTER = 'RCV_REMOVE_SAVED_FILTER';
export const ERR_REMOVE_SAVED_FILTER = 'ERR_REMOVE_SAVED_FILTER';
type REQ_REMOVE_SAVED_FILTER_TYPE = BaseAction<typeof REQ_REMOVE_SAVED_FILTER>;
type RCV_REMOVE_SAVED_FILTER_TYPE = BaseAction<typeof RCV_REMOVE_SAVED_FILTER> & {
  id: string;
};
type ERR_REMOVE_SAVED_FILTER_TYPE = BaseAction<typeof ERR_REMOVE_SAVED_FILTER>;

type ProfileAction =
  | REQ_GET_SPREADSHEET_TYPE
  | RCV_GET_SPREADSHEET_TYPE
  | ERR_GET_SPREADSHEET_TYPE
  | REQ_GET_SAVED_FILTERS_TYPE
  | RCV_GET_SAVED_FILTERS_TYPE
  | ERR_GET_SAVED_FILTERS_TYPE
  | REQ_CREATE_SAVED_FILTERS_TYPE
  | RCV_CREATE_SAVED_FILTERS_TYPE
  | ERR_CREATE_SAVED_FILTERS_TYPE
  | REQ_REMOVE_SAVED_FILTER_TYPE
  | RCV_REMOVE_SAVED_FILTER_TYPE
  | ERR_REMOVE_SAVED_FILTER_TYPE;

interface ProfileState {
  spreadsheet?: Spreadsheet;
  savedFilters?: SavedFilter[];
  requestGetSpreadsheet: RequestState;
  requestGetSavedFilters: RequestState;
  requestCreateSavedFilters: RequestState;
  requestRemoveSavedFilter: RequestState;
}

export const reportsReducerInitialState: ProfileState = {
  requestGetSpreadsheet: requestIdle(),
  requestGetSavedFilters: requestIdle(),
  requestCreateSavedFilters: requestIdle(),
  requestRemoveSavedFilter: requestIdle(),
};

const profileReducer = (state: ProfileState, action: ProfileAction) => {
  const next = cloneDeep(state);

  switch (action.type) {
    case REQ_GET_SPREADSHEET:
      next.requestGetSpreadsheet = requestInit();
      return next;

    case RCV_GET_SPREADSHEET:
      next.requestGetSpreadsheet = requestSuccessful();
      if (
        action.page !== undefined &&
        action.page !== 1 &&
        next.spreadsheet?.columns &&
        next.spreadsheet.number_of_rows
      ) {
        next.spreadsheet = {
          ...action.spreadsheet,
          filters: {
            ...next.spreadsheet.filters,
          },
          columns: {
            ...next.spreadsheet.columns,
          },
          number_of_rows: next.spreadsheet.number_of_rows,
        };
      } else {
        next.spreadsheet = action.spreadsheet;
      }
      return next;

    case ERR_GET_SPREADSHEET:
      next.requestGetSpreadsheet = requestError();
      return next;

    case REQ_GET_SAVED_FILTERS:
      next.requestGetSavedFilters = requestInit();
      return next;

    case RCV_GET_SAVED_FILTERS:
      next.requestGetSavedFilters = requestSuccessful();
      next.savedFilters = action.savedFilters;
      return next;

    case ERR_GET_SAVED_FILTERS:
      next.requestGetSavedFilters = requestError();
      return next;

    case REQ_CREATE_SAVED_FILTERS || ERR_CREATE_SAVED_FILTERS:
      next.requestGetSavedFilters = requestInit();
      return next;

    case RCV_CREATE_SAVED_FILTERS:
      next.requestGetSavedFilters = requestSuccessful();
      next.savedFilters = [...(next.savedFilters || []), action.savedFilter];
      return next;

    case ERR_CREATE_SAVED_FILTERS:
      next.requestGetSavedFilters = requestError();
      return next;

    case REQ_REMOVE_SAVED_FILTER:
      next.requestRemoveSavedFilter = requestInit();
      return next;

    case RCV_REMOVE_SAVED_FILTER:
      next.requestRemoveSavedFilter = requestSuccessful();

      const index = next.savedFilters?.findIndex((x) => x.id === action.id);
      if (index !== undefined && index > -1 && next.savedFilters) {
        next.savedFilters.splice(index, 1);
      }
      return next;

    case ERR_REMOVE_SAVED_FILTER:
      next.requestRemoveSavedFilter = requestError();
      return next;

    default:
      throw new Error(`Unsupported action type for profileReducer`);
  }
};

export default profileReducer;
