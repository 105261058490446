const _colors = {
  black: '#393a42',
  white: '#fff',
  gray: '#cbcfd2',
  grayLight: '#f6f7f8',
  grayMedium: '#EEEFF0',
  grayDark: '#666f76',
  ui: {
    green: '#275a40',
    yellow: '#ffa800',
    red: '#da2f03',
    blue: '#0d43f3',
    gray: '#f3f3f3',
    orange: '#e84e26',
    greenDark: '#6f9a18',
    redDark: '#B40B1A',
  },
  brand: {
    red: '#e2001a',
    redDark: '#bd0218',
    gray: '#393a42',
    grayDark: '#1c1c1c',
    blue: '#015491',
    blueDark: '#002d5a',
    sand: '#f2f0e9',
    sandDark: '#dedbd1',
  },
};

const theme = {
  color: {
    bg: {
      main: _colors.grayLight,
      alt: _colors.white,
      medium: _colors.grayMedium,
    },
    brand: {
      primary: {
        main: _colors.brand.red,
        hover: _colors.brand.redDark,
        text: _colors.white,
      },
      secondary: {
        main: _colors.brand.gray,
        hover: _colors.brand.grayDark,
        text: _colors.white,
      },
    },
    border: {
      main: _colors.gray,
      alt: _colors.grayDark,
    },
    text: {
      main: _colors.black,
      alt: _colors.grayDark,
      inverted: _colors.white,
    },
    link: {
      main: _colors.brand.blue,
      hover: _colors.brand.blueDark,
    },
    disabled: {
      bg: _colors.ui.gray,
      text: _colors.grayDark,
    },
    ui: {
      success: _colors.ui.green,
      successText: _colors.white,
      warning: _colors.ui.yellow,
      warningText: _colors.black,
      error: _colors.ui.red,
      errorText: _colors.white,
      info: _colors.ui.blue,
      infoText: _colors.white,
      live: _colors.ui.orange,
      liveText: _colors.white,
      signalPositive: _colors.ui.greenDark,
      signalNegative: _colors.ui.redDark,
    },
    overlay: {
      main: 'rgba(0, 0, 0, 0.25)',
      alt: 'rgba(0, 0, 0, 0.1)',
    },
  },
  breakpoint: {
    LtSm: '@media (max-width: 599px)',
    Sm: '@media (min-width: 600px)',
    LtMd: '@media (max-width: 899px)',
    Md: '@media (min-width: 900px)',
    LtLg: '@media (max-width: 1199px)',
    Lg: '@media (min-width: 1200px)',
  },
  font: {
    family: '"Gudea", sans-serif',
    lineHeight: '1.5',
    weight: {
      regular: '400',
      bold: '700',
    },
    size: {
      xsmall: '0.75rem', // 12px
      small: '0.875rem', // 14px
      regular: '1rem', // 16px
      large: '1.125rem', // 18px
      xlarge: '1.5rem', // 24px
      xxlarge: '1.75rem', // 28px
      xxxlarge: '2.5rem', // 40px
    },
  },
  size: {
    baseUnit: '8px',
    containerMaxWidth: '1600px',
    containerGutter: '56px',
    containerGutterLtSm: '1rem',
    contentMaxWidth: '680px',
    inputHeight: '56px',
  },
  zindex: {
    stateBar: '85',
    panel: '9999999999', // This is only set this high to be visible over the imBox-widget (used to be 70)
    mobileMenu: '80',
    snackbar: '90',
    fullscreenDialog: '100',
  },
};

export type Theme = typeof theme;

export default theme;
