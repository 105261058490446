import styled, { css } from 'styled-components';

import { size } from '../../layout/helpers';

interface StatusIndicatorProps {
  $tone?: 'success' | 'warning' | 'error' | 'info' | 'live';
}

export const StatusIndicator = styled.div<StatusIndicatorProps>`
  width: ${size(1.5)};
  height: ${size(1.5)};
  border-radius: 50%;

  ${({ $tone }) =>
    $tone === 'success'
      ? css`
          background-color: ${(props) => props.theme.color.ui.success};
        `
      : $tone === 'warning'
      ? css`
          background-color: ${(props) => props.theme.color.ui.warning};
        `
      : $tone === 'error'
      ? css`
          background-color: ${(props) => props.theme.color.ui.error};
        `
      : $tone === 'info'
      ? css`
          background-color: ${(props) => props.theme.color.ui.info};
        `
      : $tone === 'live'
      ? css`
          background-color: ${(props) => props.theme.color.ui.live};
        `
      : css`
          background-color: ${(props) => props.theme.color.brand.secondary.main};
        `}
`;
