import styled, { css } from 'styled-components';

import { size, rgba } from '../../layout/helpers';
import { ButtonReset } from '../../layout/reset';

const scrollBarWidth = 0.5;
const scrollBarSpacing = 2;

interface WrapperProps {
  $borderBottom?: boolean;
}

export const Wrapper = styled.div<WrapperProps>`
  ${({ $borderBottom }) =>
    $borderBottom &&
    css`
      border-bottom: 1px solid ${(props) => props.theme.color.border.main};
    `}
`;

export const List = styled.ul`
  display: flex;
  align-items: flex-end;
  list-style: none;
  padding: 0;
  margin: 0;
  overflow: auto;
  -webkit-overflow-scrolling: touch;

  &::-webkit-scrollbar {
    -webkit-appearance: none;
    height: ${size(scrollBarWidth + scrollBarSpacing)};
  }

  &::-webkit-scrollbar-track {
    background-color: ${(props) => rgba(props.theme.color.brand.secondary.main, 0.05)};
    height: ${size(scrollBarWidth)};
    border-top: ${size(scrollBarSpacing)} solid transparent;
    background-clip: padding-box;
  }

  &::-webkit-scrollbar-thumb {
    background-color: ${(props) => props.theme.color.brand.secondary.main};
    height: ${size(scrollBarWidth)};
    border-top: ${size(scrollBarSpacing)} solid transparent;
    background-clip: padding-box;
  }
`;

export const Item = styled.li`
  & + & {
    margin-left: ${size(4)};
  }
`;

interface ActionProps {
  $isActive?: boolean;
}

export const Action = styled(ButtonReset)<ActionProps>`
  position: relative;
  display: block;
  padding-bottom: ${size(2)};
  color: ${(props) => props.theme.color.text.alt};
  font-size: ${(props) => props.theme.font.size.large};
  font-weight: ${(props) => props.theme.font.weight.bold};
  white-space: nowrap;

  &:hover {
    color: ${(props) => props.theme.color.text.main};
  }

  ${({ $isActive }) =>
    $isActive &&
    css`
      color: ${(props) => props.theme.color.text.main};

      &:after {
        content: '';
        position: absolute;
        bottom: 0;
        left: 0;
        right: 0;
        height: ${size(0.5)};
        background-color: ${(props) => props.theme.color.brand.primary.main};
      }
    `}
`;
