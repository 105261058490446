export interface RequestState<T = undefined> {
  loading: boolean;
  successful: boolean;
  error: boolean;
  errorData?: T | undefined;
}

export const requestIdle = (): RequestState => ({
  loading: false,
  successful: false,
  error: false,
});

export const requestInit = (): RequestState => ({
  loading: true,
  successful: false,
  error: false,
});

export const requestSuccessful = (): RequestState => ({
  loading: false,
  successful: true,
  error: false,
});

export const requestError = (errorData?: any): RequestState => ({
  loading: false,
  successful: false,
  error: true,
  errorData,
});
