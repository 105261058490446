import styled, { css } from 'styled-components';

import { size } from '../../layout/helpers';

interface WrapperProps {
  $size?: 'small' | 'large';
}

export const Wrapper = styled.div<WrapperProps>`
  position: relative;
  border-radius: 50%;
  background-color: ${(props) => props.theme.color.brand.primary.main};
  color: ${(props) => props.theme.color.brand.primary.text};

  ${({ $size }) =>
    $size === 'small'
      ? css`
          width: ${size(4)};
          height: ${size(4)};
          font-size: ${(props) => props.theme.font.size.small};
        `
      : $size === 'large'
      ? css`
          width: ${size(7)};
          height: ${size(7)};
          font-size: ${(props) => props.theme.font.size.large};
        `
      : css`
          width: ${size(5)};
          height: ${size(5)};
          font-size: ${(props) => props.theme.font.size.regular};
        `}
`;

export const Image = styled.img`
  display: block;
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 50%;
`;

export const Initials = styled.div`
  position: absolute;
  top: 50%;
  left: 0;
  right: 0;
  transform: translateY(-50%);
  text-align: center;
  text-transform: uppercase;
  font-weight: ${(props) => props.theme.font.weight.bold};
`;
