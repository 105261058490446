import { useFetcher } from '@services/useFetcher';
import { useQuery } from '@tanstack/react-query';

import type { Profile } from 'src/@types/customerPortalApi';

export const useProfile = () => {
  const { fetchWithToken } = useFetcher();

  const path = `/profile`;
  const queryKey = path.split('/').filter((item) => item !== '');

  const queryFn = async (): Promise<Profile> => {
    const response = await fetchWithToken(path);
    return response;
  };

  return useQuery({ queryFn, queryKey });
};

export default useProfile;
