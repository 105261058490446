import React, { useState } from 'react';
import useScheduled from '@services/scheduled/useScheduled';
import { ScheduledCollectionData } from 'src/routes/Services/Scheduled';
import { format } from 'date-fns';
import { DayCard, Dot } from 'src/routes/Services/Scheduled/Week/wrapper';
import Loader from '@components/Loader';
import { sv } from 'date-fns/locale';
import { IconBox, IconError } from '@components/Icon';
import { useTranslation } from 'react-i18next';
import { H3 } from '../Heading';
import { WidgetWrapper } from './wrapper';
import { ButtonRouterLink } from '@components/Button';

type ItemScheduledProps = {
  isSmall?: boolean;
};

const ItemScheduled: React.FC<ItemScheduledProps> = ({ isSmall }) => {
  const [scheduled, setScheduled] = useState<ScheduledCollectionData[]>();
  const today = new Date();
  const { t } = useTranslation();

  const ScheduledLink = () => (
    <ButtonRouterLink to="/scheduled" $tiny={true}>
      {t('widget_scheduled_see_all')}
    </ButtonRouterLink>
  );

  const { isLoading, isError } = useScheduled(
    {
      date: {
        date_from: format(today, 'yyyy-MM-dd'),
        date_to: format(today, 'yyyy-MM-dd'),
      },
    },
    (value) => {
      const collections = value?.data.flatMap((item: any) => item?.collections);
      setScheduled(collections);
    }
  );

  if (isError) {
    return (
      <WidgetWrapper>
        <IconBox>
          <IconError />
        </IconBox>
        {!isSmall && (
          <>
            <H3>{t('widget_scheduled_error')}</H3>
            <p>{t('navigation_scheduled_description')}</p>
            <ScheduledLink />
          </>
        )}
      </WidgetWrapper>
    );
  }

  if (isLoading) {
    return (
      <WidgetWrapper>
        <IconBox>
          <Loader style={{ transform: 'scale(.75)' }} />
        </IconBox>
        {!isSmall && (
          <>
            <H3>{t('widget_scheduled_loading')}</H3>
            <p>{t('navigation_scheduled_description')}</p>
            <ScheduledLink />
          </>
        )}
      </WidgetWrapper>
    );
  }

  return (
    <WidgetWrapper>
      <IconBox>
        <DayCard as="div" $isSmall={true}>
          <p className="day">{format(today, 'EE', { locale: sv })}</p>
          <p className="date">{format(today, 'd')}</p>
          {!!scheduled?.length && <Dot />}
        </DayCard>
      </IconBox>
      {!isSmall && scheduled && !isNaN(scheduled.length) && (
        <>
          <H3>{t('widget_scheduled_count', { count: scheduled.length })}</H3>
          <p>{t('navigation_scheduled_description')}</p>
          <ScheduledLink />
        </>
      )}
    </WidgetWrapper>
  );
};

export default ItemScheduled;
