import React, { useCallback, useMemo, useRef, useState } from 'react';
import { SnackbarContext } from './SnackbarContext';

export interface SnackBarOptions {
  onError?: React.ReactNode;
  onSuccess?: React.ReactNode;
}

export interface SnackbarHttp {
  onSuccess?: () => void;
  onError?: () => void;
}

export type SnackbarType = 'error' | 'success' | 'warning' | 'info';

export interface Snackbar {
  id: number;
  type: SnackbarType;
  reactNode: React.ReactNode;
}

export interface SnackbarBase {
  type: SnackbarType;
  reactNode: React.ReactNode;
}

interface SnackbarProviderProps {
  children: React.ReactNode;
}

export const snackbarCreator = (
  snackbarOptions: SnackBarOptions | undefined,
  pushSnackbar: (snackbar: SnackbarBase) => void
): SnackbarHttp | undefined =>
  snackbarOptions
    ? {
        onSuccess: snackbarOptions.onSuccess
          ? () => pushSnackbar({ type: 'success', reactNode: snackbarOptions.onSuccess })
          : undefined,
        onError: snackbarOptions.onError
          ? () => pushSnackbar({ type: 'error', reactNode: snackbarOptions.onError })
          : undefined,
      }
    : undefined;

const SnackbarProvider = ({ children }: SnackbarProviderProps) => {
  const [snackbars, setSnackbars] = useState<Snackbar[]>([]);
  const snackbarsRef = useRef(snackbars);
  snackbarsRef.current = snackbars;

  const pushSnackbar = useCallback(
    (snackbar: SnackbarBase) =>
      setSnackbars((s) => [
        ...s,
        {
          id: new Date().valueOf(),
          ...snackbar,
        },
      ]),
    []
  );

  const pushSnackbarSuccess = (reactNode: React.ReactNode) =>
    pushSnackbar({ type: 'success', reactNode });

  const pushSnackbarError = (reactNode: React.ReactNode) =>
    pushSnackbar({ type: 'error', reactNode });

  const removeSnackbar = useCallback((id: number) => {
    const nextSnackbars = snackbarsRef.current.slice();
    const index = nextSnackbars.findIndex((x) => x.id === id);
    if (index > -1) {
      nextSnackbars.splice(index, 1);
      setSnackbars(nextSnackbars);
    }
  }, []);

  const value: SnackbarContext = useMemo(
    () => ({
      snackbars,
      pushSnackbar,
      removeSnackbar,
      pushSnackbarSuccess,
      pushSnackbarError,
    }),
    [snackbars]
  );

  return <SnackbarContext.Provider value={value}>{children}</SnackbarContext.Provider>;
};

export default SnackbarProvider;
