import React, { useContext } from 'react';
import { IconDirDown, IconDirRight } from '../Icon';
import { Container } from '../Container';
import {
  NavItem,
  NavLink,
  NavAction,
  NavLinkIcon,
  NavLinkTitle,
  NavActionIcon,
  NavActionTitle,
  SubNav,
  SubNavList,
  SubNavGrid,
  SubNavWidgets,
  SubNavFooter,
} from './wrapper';
import { HeaderCtx } from './HeaderContext';
import { H2 } from '../Heading';
import SubNavItem, { SubNavItemProps } from './SubNavItem';
import { useTranslation } from 'react-i18next';

interface NavigationListItemProps {
  navigation: NavigationItem;
  pathname: string;
  onClick?: (e: React.MouseEvent<HTMLAnchorElement>) => void;
}

export interface NavigationItem {
  title: string;
  path?: string;
  exact?: boolean;
  authority?: ('userAdmin' | 'clientAdmin')[];
  permission?: string;
  subNav?: SubNavItemProps[];
  description?: string;
  widgets?: JSX.Element[];
}

const NavigationListItem = ({ navigation, pathname, onClick }: NavigationListItemProps) => {
  const { isSelected, toggleSubNav, activeSubNavs } = useContext(HeaderCtx);
  const { t } = useTranslation();
  const subNavInTwoCols =
    !navigation.widgets && navigation?.subNav && navigation?.subNav?.length > 3;

  return (
    <NavItem>
      {navigation.path && (
        <NavLink
          to={navigation.path}
          title={navigation.title}
          $isActive={!!isSelected(pathname, navigation.path, navigation.exact)}
          onClick={onClick}
        >
          <NavLinkTitle>{navigation.title}</NavLinkTitle>
          <NavLinkIcon>
            <IconDirRight />
          </NavLinkIcon>
        </NavLink>
      )}

      {navigation.subNav && navigation.subNav.length > 0 && (
        <>
          <NavAction
            onClick={() => toggleSubNav(navigation.title)}
            title={navigation.title}
            $isActive={
              activeSubNavs.length
                ? activeSubNavs.includes(navigation.title)
                : !!navigation.subNav.find((x) => x.path === pathname)
            }
          >
            <NavActionTitle>{navigation.title}</NavActionTitle>
            <NavActionIcon>
              <IconDirDown />
            </NavActionIcon>
          </NavAction>
          {activeSubNavs.includes(navigation.title) && (
            <SubNav>
              <Container>
                <H2>{navigation.title}</H2>
                <SubNavGrid>
                  <SubNavList>
                    {navigation.subNav.map((subnav, i) => {
                      if (!subNavInTwoCols || (subNavInTwoCols && i < 3)) {
                        return (
                          <SubNavItem
                            key={`${subnav.path}-${i}`}
                            onClick={onClick}
                            pathname={pathname}
                            {...subnav}
                          />
                        );
                      }
                    })}
                  </SubNavList>

                  {subNavInTwoCols && (
                    <SubNavList>
                      {navigation.subNav.map((subnav, i) => {
                        if (subNavInTwoCols && i >= 3) {
                          return (
                            <SubNavItem
                              key={`${subnav.path}-${i}`}
                              onClick={onClick}
                              pathname={pathname}
                              {...subnav}
                            />
                          );
                        }
                      })}
                    </SubNavList>
                  )}

                  {navigation.widgets && <SubNavWidgets>{navigation.widgets}</SubNavWidgets>}
                </SubNavGrid>
                <SubNavFooter>
                  <div>{t('navigation_footer_title')}</div>
                  <div>{t('navigation_footer_text')}</div>
                </SubNavFooter>
              </Container>
            </SubNav>
          )}
        </>
      )}
    </NavItem>
  );
};

export default NavigationListItem;
