import React, { useState, useCallback, useContext, useRef, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { Link as RRDLink } from 'react-router-dom';

import {
  Wrapper,
  Inner,
  Main,
  MainInner,
  MainInnerBackdrop,
  MainInnerDialog,
  MenuBtn,
  Logo,
  LogoImg,
  Nav,
  NavHeader,
  NavHeading,
  NavClose,
  NavCloseBtn,
  NavBody,
  Profile,
  ProfileBtn,
  ProfileAvatar,
  ProfileIcon,
  Backdrop,
  TopNav,
} from './wrapper';
import { Container } from '../Container';
import { IconDirDown, IconClose, IconMenu } from '../Icon';
import { H2 } from '../Heading';
import Avatar from '../Avatar';

import logotype from '../../assets/logotype/remondis-logo.svg';

import NavigationList from './NavigationList';
import useAuth from '../../State/Auth/useAuth';
import PublicHeader from './PublicHeader';
import { useRoutes } from '../../routes/useRoutes';
import { HeaderCtx } from './HeaderContext';
import HeaderProfileMenu from './HeaderProfileMenu';
import useClickOutside from '../../hooks/useClickOutside';
import useImpersonate from '@State/Impersonate/useImpersonate';

const Header = () => {
  const wrapperRef = useRef<HTMLDivElement>(null);
  const { t } = useTranslation();
  const { parsedToken, signOut, authenticated } = useAuth();
  const { isPrivateRoute } = useRoutes();
  const { showMobileMenu, toggleMobileMenu, closeSubNav, activeSubNavs } = useContext(HeaderCtx);
  const { impersonate } = useImpersonate();

  const [isAdmin, setIsAdmin] = useState<{ clientAdmin: boolean; userAdmin: boolean }>({
    clientAdmin: !parsedToken?.clientadmin,
    userAdmin: !!parsedToken?.useradmin,
  });

  const [userPanel, setUserPanel] = useState(false);
  const onToggleUserPanel = useCallback(() => setUserPanel(!userPanel), [userPanel]);

  const userInitials = parsedToken
    ? `${parsedToken?.given_name.charAt(0)}${parsedToken?.family_name.charAt(0)}`
    : '';

  // Check impersonate premissions before setting current user premissions
  // A impersonate user can not be a userAdmin
  useEffect(() => {
    if (impersonate) {
      setIsAdmin({ clientAdmin: impersonate?.clientadmin, userAdmin: false });
    } else {
      setIsAdmin({
        clientAdmin: parsedToken?.clientadmin ? parsedToken?.clientadmin : false,
        userAdmin: parsedToken?.useradmin ? parsedToken?.useradmin : false,
      });
    }
  }, [impersonate, parsedToken]);

  useClickOutside(wrapperRef, closeSubNav);

  return !isPrivateRoute && !authenticated ? (
    <PublicHeader />
  ) : (
    <>
      <Wrapper>
        {activeSubNavs.length > 0 && <Backdrop onClick={closeSubNav} />}
        <TopNav>
          <Container>
            <Inner>
              <Main>
                <MenuBtn onClick={toggleMobileMenu} title={t('header_mobile_nav_open_btn_title')}>
                  <IconMenu />
                </MenuBtn>
                <MainInner $isOpen={showMobileMenu}>
                  <MainInnerBackdrop
                    onClick={toggleMobileMenu}
                    title={t('header_mobile_nav_close_btn_title')}
                  />
                  <MainInnerDialog>
                    <NavHeader>
                      <NavHeading>
                        <H2 as="div" $noMargin>
                          {t('header_mobile_nav_heading')}
                        </H2>
                      </NavHeading>
                      <NavClose>
                        <NavCloseBtn
                          onClick={toggleMobileMenu}
                          title={t('header_mobile_nav_close_btn_title')}
                          aria-label={t('header_mobile_nav_close_btn_title')}
                        >
                          <IconClose />
                        </NavCloseBtn>
                      </NavClose>
                    </NavHeader>
                    <NavBody>
                      <Logo>
                        <RRDLink to="/" title={t('navigation_home_title')}>
                          <LogoImg src={logotype} alt={t('header_logotype_alt')} />
                        </RRDLink>
                      </Logo>
                      <Nav ref={wrapperRef}>
                        <NavigationList
                          userAdmin={isAdmin.userAdmin}
                          clientAdmin={isAdmin.clientAdmin}
                        />
                      </Nav>
                    </NavBody>
                  </MainInnerDialog>
                </MainInner>
              </Main>
              <Profile>
                <ProfileBtn onClick={onToggleUserPanel} title={t('toggle_profile')}>
                  <ProfileAvatar>
                    <Avatar initials={userInitials} />
                  </ProfileAvatar>
                  <ProfileIcon>
                    <IconDirDown />
                  </ProfileIcon>
                </ProfileBtn>
                {userPanel && <HeaderProfileMenu onClose={onToggleUserPanel} onSignOut={signOut} />}
              </Profile>
            </Inner>
          </Container>
        </TopNav>
      </Wrapper>
    </>
  );
};

export default Header;
