import { createContext } from 'react';

import { RequestState } from '../../http/requestState';
import { Impersonate } from './ImpersonateModels';

export interface ImpersonateContextProps {
  impersonate?: Impersonate;
  impersonating?: string;
  hasFetched?: boolean;
  requestGetImpersonate: RequestState;
  requestSetImpersonate: RequestState;
  requestRemoveImpersonate: RequestState;
  getImpersonate: () => void;
  setImpersonate: (email: string) => void;
  removeImpersonate: () => void;
}

export const ImpersonateContext = createContext<ImpersonateContextProps | undefined>(undefined);
