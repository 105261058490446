import styled, { css } from 'styled-components';

import { ButtonReset } from '../../layout/reset';
import { size } from '../../layout/helpers';

export const Group = styled.div`
  margin: ${size(-3)} 0;
`;

export const Header = styled(ButtonReset)`
  display: flex;
  align-items: center;
  width: 100%;
  padding: ${size(3)} 0;
  text-align: left;
  line-height: ${(props) => props.theme.font.lineHeight};
`;

export const HeaderTitle = styled.div`
  display: flex;
  align-items: center;
  flex: 1 1 auto;
  min-width: 0;

  ${Header}:hover & {
    text-decoration: underline;
  }
`;

export const HeaderTitleText = styled.div`
  font-size: ${(props) => props.theme.font.size.large};
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;

export const HeaderTitleIndicator = styled.div`
  flex-shrink: 0;
  position: relative;
  padding-left: ${size(0.25)};
  height: 1.5em;

  &:before {
    content: '';
    position: absolute;
    top: 0;
    width: ${size(1)};
    height: ${size(1)};
    border-radius: 50%;
    background-color: ${(props) => props.theme.color.brand.primary.main};
  }
`;

export const HeaderIcon = styled.div`
  flex-shrink: 0;
  padding-left: ${size(2)};
`;

export const Body = styled.div`
  flex: 1 1 auto;
  padding-bottom: ${size(3)};
`;

interface WrapperProps {
  $boxed?: boolean;
}

export const Wrapper = styled.div<WrapperProps>`
  flex: 1 1 auto;

  & + & {
    border-top: 1px solid ${(props) => props.theme.color.border.main};
  }

  ${({ $boxed }) =>
    $boxed &&
    css`
      background-color: ${(props) => props.theme.color.bg.alt};

      ${Header} {
        padding: ${size(3)};
      }

      ${HeaderTitle} {
        flex: 0 1 auto;
      }

      ${Body} {
        padding-left: ${size(3)};
        padding-right: ${size(3)};
      }
    `}
`;
