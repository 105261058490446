import React from 'react';

import { Wrapper, Dialog, DialogInner, DialogContent, Action, Actions } from './wrapper';
import { Container } from '../Container';
import { Button } from '../Button';

interface IFullscreenDialogButton {
  onClick: () => void;
  text: string;
  title: string;
  disabled?: boolean;
  tone?: 'primary' | 'outline';
}

export interface IFullscreenDialog {
  children: React.ReactNode;
  buttons?: IFullscreenDialogButton[];
}

const FullscreenDialog = ({ children, buttons }: IFullscreenDialog) => (
  <Wrapper>
    <Dialog>
      <Container>
        <DialogInner>
          <DialogContent>{children}</DialogContent>
          <Actions>
            {buttons &&
              buttons.map((button, index) => (
                <Action key={index}>
                  <Button
                    onClick={button.onClick}
                    title={button.title}
                    disabled={button.disabled}
                    $tone={button.tone}
                    $fullWidth
                  >
                    {button.text}
                  </Button>
                </Action>
              ))}
          </Actions>
        </DialogInner>
      </Container>
    </Dialog>
  </Wrapper>
);

export default FullscreenDialog;
