import styled from 'styled-components';

import { size } from '../../layout/helpers';

export const Wrapper = styled.footer`
  background-color: ${(props) => props.theme.color.brand.secondary.main};
  color: ${(props) => props.theme.color.text.inverted};
  padding: ${size(7)} 0;
  padding-bottom: calc(${size(7)} + env(safe-area-inset-bottom));

  a {
    &:hover {
      color: ${(props) => props.theme.color.text.inverted};
      text-decoration: underline;
    }
  }
`;
