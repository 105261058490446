import cloneDeep from 'lodash.clonedeep';
import { BaseAction } from '../../@types/Action';
import {
  RequestState,
  requestInit,
  requestSuccessful,
  requestError,
  requestIdle,
} from '../../http/requestState';

export const REQ_GET_PERMISSIONS = 'REQ_GET_PERMISSIONS';
export const RCV_GET_PERMISSIONS = 'RCV_GET_PERMISSIONS';
export const ERR_GET_PERMISSIONS = 'ERR_GET_PERMISSIONS';

type REQ_GET_PERMISSIONS_TYPE = BaseAction<typeof REQ_GET_PERMISSIONS>;
type RCV_GET_PERMISSIONS_TYPE = BaseAction<typeof RCV_GET_PERMISSIONS> & {
  permissions: string[];
};
type ERR_GET_PERMISSIONS_TYPE = BaseAction<typeof ERR_GET_PERMISSIONS>;

type PermissionsAction =
  | REQ_GET_PERMISSIONS_TYPE
  | RCV_GET_PERMISSIONS_TYPE
  | ERR_GET_PERMISSIONS_TYPE;

interface PermissionsState {
  permissions?: string[];
  requestGet: RequestState;
}

export const permissionsReducerInitialState: PermissionsState = {
  requestGet: requestIdle(),
};

const profileReducer = (state: PermissionsState, action: PermissionsAction) => {
  const next = cloneDeep(state);
  switch (action.type) {
    case REQ_GET_PERMISSIONS:
      next.requestGet = requestInit();
      return next;
    case RCV_GET_PERMISSIONS:
      next.requestGet = requestSuccessful();
      next.permissions = action.permissions;
      return next;
    case ERR_GET_PERMISSIONS:
      next.requestGet = requestError();
      return next;

    default:
      throw new Error(`Unsupported action type for profileReducer`);
  }
};

export default profileReducer;
