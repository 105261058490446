import { useContext } from 'react';
import { ImpersonateContext, ImpersonateContextProps } from './ImpersonateContext';

const useImpersonate = (): ImpersonateContextProps => {
  const context = useContext(ImpersonateContext);

  if (!context) {
    throw new Error('useImpersonate must be used within a ImpersonateContext');
  }

  return context;
};
export default useImpersonate;
