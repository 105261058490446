import styled, { css } from 'styled-components';

import { size } from '../../layout/helpers';

interface HeadingBaseProps {
  $noMargin?: boolean;
  $contentWidth?: boolean;
  $weight?: string;
  as?: string;
}

export const HeadingBase = styled.div<HeadingBaseProps>`
  display: block;
  margin-top: 0;
  margin-bottom: ${size(2)};
  line-height: 1.2;

  ${({ $noMargin }) =>
    $noMargin &&
    css`
      margin-bottom: 0;
    `}

  ${({ $contentWidth }) =>
    $contentWidth &&
    css`
      max-width: ${(props) => props.theme.size.contentMaxWidth};
    `}

    ${({ $weight }) =>
    $weight === 'bold'
      ? css`
          font-weight: ${(props) => props.theme.font.weight.bold};
        `
      : css`
          font-weight: ${(props) => props.theme.font.weight.regular};
        `}
`;

export const H1 = styled(HeadingBase).attrs<{ as: string }>((props) => ({
  as: props.as || 'h1',
}))`
  font-size: ${(props) => props.theme.font.size.xxxlarge};
  letter-spacing: -0.5px;
  font-weight: ${(props) => props.theme.font.weight.bold};
  ${(props) => props.theme.breakpoint.LtSm} {
    font-size: 2rem;
  }
`;

export const H2 = styled(HeadingBase).attrs<{ as: string }>((props) => ({
  as: props.as || 'h2',
}))`
  font-size: ${(props) => props.theme.font.size.xxlarge};
  letter-spacing: -0.5px;
`;

export const H3 = styled(HeadingBase).attrs<{ as: string }>((props) => ({
  as: props.as || 'h3',
}))`
  font-size: ${(props) => props.theme.font.size.xlarge};
  letter-spacing: -0.5px;
`;

export const H4 = styled(HeadingBase).attrs<{ as: string }>((props) => ({
  as: props.as || 'h4',
}))`
  font-size: ${(props) => props.theme.font.size.large};
`;

export const H5 = styled(HeadingBase).attrs<{ as: string }>((props) => ({
  as: props.as || 'h5',
}))`
  font-size: ${(props) => props.theme.font.size.regular};
`;

export const H6 = styled(HeadingBase).attrs<{ as: string }>((props) => ({
  as: props.as || 'h6',
}))`
  font-size: ${(props) => props.theme.font.size.small};
`;

export const StyledH1 = styled(H1)`
  font-family: 'Permanent Marker';
`;

export const StyledH2 = styled(H2)`
  font-family: 'Permanent Marker';
`;

export const StyledH3 = styled(H3)`
  font-family: 'Permanent Marker';
`;
