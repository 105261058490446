import * as React from 'react';

import { Portal, PortalNamespace } from '../../components/Portal';

import {
  Body,
  Content,
  Dialog,
  Header,
  Heading,
  UiBlock,
  Wrapper,
  Close,
  CloseBtn,
} from './wrapper';
import { IconClose } from '../Icon';

export interface IModal {
  children: React.ReactNode;
  onClose?: (e: React.MouseEvent<HTMLDivElement | HTMLButtonElement, MouseEvent>) => void;
  portalId?: string;
  title?: string;
  overlay?: boolean;
}

const Modal = ({ portalId, title, onClose, children, overlay }: IModal) => {
  return (
    <Portal id={portalId || PortalNamespace.DefaultPortal}>
      <Wrapper overlay={overlay}>
        {onClose && <UiBlock onClick={onClose} title="Stäng modal" />}
        <Dialog>
          <Content>
            {(title || onClose) && (
              <Header>
                <Heading>{title}</Heading>
                {onClose && (
                  <Close>
                    <CloseBtn onClick={onClose} title="Stäng modal">
                      <IconClose />
                    </CloseBtn>
                  </Close>
                )}
              </Header>
            )}
            <Body>{children}</Body>
          </Content>
        </Dialog>
      </Wrapper>
    </Portal>
  );
};

export default Modal;
