import styled, { css, keyframes } from 'styled-components';

import { size, rgba } from '../../layout/helpers';

const shineWidth = 400;

const shine = keyframes`
  from {
    transform: translateX(0);
  }

  to {
    transform: translateX(100%);
  }
`;

const skeletonText = css`
  & + & {
    margin-top: ${size(0.5)};
  }
`;

const skeletonCircle = css`
  border-radius: 50%;
`;

const skeletonDoughnut = css`
width: 100%;
padding-bottom: 100%;
border-radius: 50%;

&:after {
  content '';
  position: absolute;
  width: 75%;
  height: 75%;
  border-radius: 50%;
  background: white;
  top: 50%;
  left: 50%;
  transform: translateX(-50%) translateY(-50%);
}
`;

interface ISkeleton {
  $variant?: 'text' | 'circle' | 'doughnut';
  $width?: number | string;
  $height?: number | string;
  $ratio?: number;
  $doughnut?: boolean;
}

export const Skeleton = styled.div<ISkeleton>`
  position: relative;
  max-width: 100%;
  background-color: ${(props) => props.theme.color.disabled.bg};
  border-radius: 4px;
  overflow: hidden;

  & + & {
    margin-top: ${size(1)};
  }

  &:before {
    content: '';
    position: absolute;
    top: 0;
    left: ${shineWidth * -1}px;
    height: 100%;
    width: calc(100% + ${shineWidth}px);
    background-image: linear-gradient(
      90deg,
      ${(props) => rgba(props.theme.color.bg.main, 0)},
      ${(props) => props.theme.color.bg.main},
      ${(props) => rgba(props.theme.color.bg.main, 0)}
    );
    background-size: ${shineWidth}px;
    background-repeat: no-repeat;
    animation: ${shine} 2s ease infinite;

    @media (prefers-reduced-motion) {
      animation: none;
    }
  }

  ${({ $width, $height }) =>
    css`
      height: ${$height || '1.5em'};
      width: ${$width || '100%'};
    `}

  ${({ $ratio }) =>
    $ratio &&
    css`
      width: 100%;
      height: 0;
      padding-bottom: ${$ratio * 100}%;
    `}

  ${({ $variant }) =>
    $variant &&
    css`
      ${$variant === 'text' && skeletonText};
      ${$variant === 'circle' && skeletonCircle};
      ${$variant === 'doughnut' && skeletonDoughnut};
    `}
`;
