import styled, { css } from 'styled-components';

import { size } from '../../layout/helpers';
import { linkBase } from '../Link';
import { Icon } from '../Icon';

interface ILinkButton {
  $hasContent?: boolean;
  $tone?: 'main' | 'alt' | 'inverted' | 'error' | 'currentColor';
  $currentColor?: boolean;
}

export const LinkButton = styled.button.attrs(() => ({
  role: 'button',
  tabIndex: 0,
}))<ILinkButton>`
  ${linkBase}
  cursor: pointer;
  background: transparent;
  border: 0;
  font-size: inherit;
  padding: 0;

  &:hover {
    color: ${(props) => props.theme.color.brand.primary.main};
  }
  &:focus,
  &:focus-visible {
    outline-offset: 1px;
  }
  ${({ $hasContent }) =>
    $hasContent &&
    css`
      display: inline-flex;
      align-items: center;
    `}

  ${({ $tone }) =>
    $tone === 'main'
      ? css`
          color: ${(props) => props.theme.color.text.main};
        `
      : $tone === 'alt'
      ? css`
          color: ${(props) => props.theme.color.text.alt};
        `
      : $tone === 'inverted'
      ? css`
          color: ${(props) => props.theme.color.text.inverted};
          &:hover {
            color: ${(props) => props.theme.color.border.main};
          }
        `
      : $tone === 'error'
      ? css`
          color: ${(props) => props.theme.color.ui.error};
        `
      : $tone === 'currentColor'
      ? css`
          color: currentColor;
        `
      : undefined}
`;

interface ILinkButtonContent {
  $largeIcon?: boolean;
}

export const LinkButtonContent = styled.span<ILinkButtonContent>`
  display: block;

  & + & {
    margin-left: ${size(1)};
  }

  ${({ $largeIcon }) =>
    $largeIcon &&
    css`
      ${Icon} {
        font-size: ${(props) => props.theme.font.size.xlarge};
        margin-top: -0.625em;
        margin-bottom: -0.625em;
      }
    `}
`;
