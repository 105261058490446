import styled from 'styled-components';

import { size } from '../../layout/helpers';
import { fadeIn, noTransform } from '../../layout/keyframes';

export const Wrapper = styled.div.attrs(() => ({
  role: 'dialog',
}))`
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  z-index: ${(props) => props.theme.zindex.fullscreenDialog};

  &:before {
    content: '';
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background-color: ${(props) => props.theme.color.overlay.main};
    animation: ${fadeIn} 200ms ease;

    @media (prefers-reduced-motion) {
      animation: none;
    }
  }
`;

export const Dialog = styled.div`
  position: relative;
  padding: ${size(7)} 0;
  padding-bottom: calc(${size(7)} + env(safe-area-inset-bottom));
  background-color: ${(props) => props.theme.color.bg.alt};
  transform: translateY(100%);
  animation: ${noTransform} 200ms ease forwards;
  max-height: 100vh;
  overflow: auto;

  @media (prefers-reduced-motion) {
    animation-duration: 0s;
  }
`;

export const DialogInner = styled.div`
  display: flex;
  flex-flow: row wrap;
  gap: ${size(2)};
`;

export const DialogContent = styled.div`
  width: 100%;
  flex: 1 1 max(500px, calc(100% - 400px));
`;

export const Actions = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${size(1)};
  justify-content: flex-end;
  min-height: 100%;
  flex: 1 0 max-content;
  padding-bottom: ${size(2)};
`;

export const Action = styled.div`
  ${(props) => props.theme.breakpoint.Sm} {
    align-self: flex-end;
    width: 100%;
  }
`;
