import { useContext } from 'react';
import { PermissionsContext, PermissionsContextProps } from './PermissionsContext';

const usePermissions = (): PermissionsContextProps => {
  const context = useContext(PermissionsContext);

  if (!context) {
    throw new Error('usePermissions must be used within a PermissionsProvider');
  }

  return context;
};
export default usePermissions;
