import { Container } from '@components/Container';
import { H4 } from '@components/Heading';
import { IconClose } from '@components/Icon';
import { Text } from '@components/Text';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { getCookie, setCookie } from 'src/Utils/cookie';
import styled from 'styled-components';

function hashCode(input: string) {
  let hash = 0;

  if (input.length == 0) {
    return hash;
  }

  for (let i = 0, len = input.length; i < len; i++) {
    const chr = input.charCodeAt(i);
    hash = (hash << 5) - hash + chr;
    hash |= 0; // Convert to 32bit integer
  }
  return hash;
}

type WrapperProps = {
  $warning?: boolean;
};

const Wrapper = styled.section<WrapperProps>`
  color: white;
  background: ${(props) => (props.$warning ? '#b40b1a' : props.theme.color.bg.medium)};
  color: ${(props) => (props.$warning ? 'white' : props.theme.color.text.main)};
  display: flex;
  padding: 32px 0;
`;

const CloseButton = styled.span`
  cursor: pointer;
  padding: 1rem;
  margin-left: 1rem;
`;

const Inner = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

const TextWrapper = styled.div`
  max-width: 900px;
  a {
    color: inherit;
  }
`;

export const MaintenanceBanner = () => {
  const { t } = useTranslation();
  const message = t('maintenance_banner_message');
  const cookieName = `dismissed-maintenance-banner-${hashCode(message)}`;
  const [showBanner, setShowBanner] = useState(getCookie(cookieName) !== 'true');

  if (!showBanner) {
    return null;
  }

  function handleClick() {
    setShowBanner(false);
    setCookie(cookieName, true);
  }

  return (
    <Wrapper>
      <Container>
        <Inner>
          <TextWrapper>
            <H4>De tekniska störningarna är åtgärdade.</H4>
            <Text>
              Tack för ditt tålamod! För eventuella frågor, ring vår kundtjänst på{' '}
              <a href="tel:0771545000" title="Ring kundtjänst">
                0771 – 545 000
              </a>
              .
            </Text>
          </TextWrapper>
          <CloseButton onClick={handleClick}>
            <IconClose />
          </CloseButton>
        </Inner>
      </Container>
    </Wrapper>
  );
};

export default MaintenanceBanner;
