import styled, { css } from 'styled-components';

import { size, rgba } from '../../layout/helpers';
import { noTransform, fadeIn } from '../../layout/keyframes';
import { ButtonReset } from '../../layout/reset';

export const Wrapper = styled.div`
  position: fixed;
  top: ${size(1)};
  right: ${size(1)};
  z-index: ${(props) => props.theme.zindex.snackbar};
`;

type ItemProps = React.DetailedHTMLProps<React.HTMLAttributes<HTMLDivElement>, HTMLDivElement> & {
  $type: 'error' | 'success' | 'danger' | 'warning';
  $fixedTop?: boolean;
  $fixedBottom?: boolean;
};

export const Snack = styled.div.attrs(() => ({
  role: 'alert',
}))<ItemProps>`
  display: flex;
  align-items: flex-start;
  width: 400px;
  max-width: calc(100vw - ${size(2)});
  padding: ${size(3)};
  background-color: ${(props) => props.theme.color.bg.alt};
  color: ${(props) => props.theme.color.text.main};
  box-shadow: 0 4px 8px ${(props) => rgba(props.theme.color.brand.secondary.main, 0.2)};
  transform: translateY(${size(1)});
  animation: ${noTransform} 300ms ease forwards, ${fadeIn} 300ms ease;
  z-index: ${(props) => props.theme.zindex.snackbar};
  whitespace: initial;

  border-left: ${size(1)} solid ${(props) =>
  ({ $type }) =>
    ({
      success: props.theme.color.ui.success,
      danger: props.theme.color.ui.error,
      error: props.theme.color.ui.error,
      warning: props.theme.color.ui.warning,
    }[$type])};

  & + & {
    margin-top: ${size(0.5)};
  }
  ${({ $fixedTop }) =>
    $fixedTop &&
    css`
      position: fixed;
      top: 0;
      right: 0;
    `}
   
    ${({ $fixedBottom }) =>
      $fixedBottom &&
      css`
        position: fixed;
        bottom: 2em;
        left: 50%;
        translate: -50%;
      `})}


`;

export const Content = styled.div`
  flex: 1 1 auto;
  font-size: 1rem;
`;

export const Action = styled.div`
  flex-shrink: 0;
  padding-left: ${size(2)};
`;

export const Close = styled(ButtonReset)`
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  transform-origin: 50% 50%;
  transition: transform 200ms ease;

  &:before {
    content: '';
    position: absolute;
    top: 50%;
    left: 50%;
    width: ${size(4)};
    height: ${size(4)};
    transform: translate(-50%, -50%);
    background-color: ${(props) => props.theme.color.bg.main};
    border-radius: 50%;
    opacity: 0;
    transition: opacity 200ms ease;
  }

  &:hover {
    &:before {
      opacity: 1;
    }
  }

  &:active {
    transform: scale(0.95);
  }

  > * {
    position: relative;
    z-index: 1;
  }
`;
