import React from 'react';
import { useTranslation } from 'react-i18next';

import useImpersonate from './State/Impersonate/useImpersonate';

import StateBar from './components/StateBar';
import Avatar from './components/Avatar';
import Loader from './components/Loader';
import { Text } from './components/Text';
import { Columns, Column } from './components/Columns';
import { LinkButton, LinkButtonContent } from './components/LinkButton';
import { StatusIndicator } from './components/StatusIndicator';
import { useHistory } from 'react-router-dom';

const ImpersonateBar = () => {
  const { t } = useTranslation();
  const { impersonate, requestRemoveImpersonate, removeImpersonate } = useImpersonate();
  const history = useHistory();

  if (!impersonate) {
    return null;
  }

  const onClick = () => {
    removeImpersonate();
    // Go back to users when you finished a impersonate state
    history.push('/users');
  };

  return (
    <StateBar
      rightContent={
        <LinkButton
          onClick={onClick}
          title={t('impersonate_bar_terminate_button_title', {
            user: 'Förnamn Efternamn',
          })}
          $tone="inverted"
          disabled={requestRemoveImpersonate.loading}
          $hasContent
        >
          <LinkButtonContent>{t('impersonate_bar_terminate_button_text')}</LinkButtonContent>
          {requestRemoveImpersonate.loading && (
            <LinkButtonContent>
              <Loader inlineBlock />
            </LinkButtonContent>
          )}
        </LinkButton>
      }
    >
      <Columns $spacing={4} $valign="center">
        <Column>
          <Columns $spacing={1} $valign="center">
            <Column>
              <StatusIndicator $tone="live" />
            </Column>
            <Column>
              <Text $weight="bold">{t('impersonate_bar_active')}</Text>
            </Column>
          </Columns>
        </Column>
        <Column>
          <Columns $spacing={1} $valign="center">
            <Column>
              <Avatar
                initials={`${impersonate.firstname?.substring(
                  0,
                  1
                )}${impersonate.lastname?.substring(0, 1)}`}
                size="small"
              />
            </Column>
            <Column>
              {impersonate.firstname} {impersonate.lastname}
            </Column>
          </Columns>
        </Column>
      </Columns>
    </StateBar>
  );
};

export default ImpersonateBar;
