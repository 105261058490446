import styled, { css } from 'styled-components';
import { Link as RRDLink } from 'react-router-dom';

import { size } from '../../layout/helpers';
import { HashLink as RRDHashLink } from 'react-router-hash-link';

export const linkBase = css`
  text-decoration: none;
  color: ${(props) => props.theme.color.link.main};
  font-family: ${(props) => props.theme.font.family};

  &:hover {
    text-decoration: underline;
    color: ${(props) => props.theme.color.link.hover};
  }
`;

interface ILink {
  $currentColor?: boolean;
  $hasContent?: boolean;
}

export const Link = styled.a.attrs<{ as: string }>((props) => ({
  as: props.as || 'a',
}))<ILink>`
  ${linkBase}

  &:is(button) {
    background: none;
    border: none;
    padding: 0;
    font: inherit;
    cursor: pointer;
  }

  ${({ $currentColor }) =>
    $currentColor &&
    css`
      color: currentColor;
    `}

  ${({ $hasContent }) =>
    $hasContent &&
    css`
      display: inline-flex;
      align-items: center;
    `}
`;

export const RouterLink = styled(RRDLink)<ILink>`
  ${linkBase}

  ${({ $currentColor }) =>
    $currentColor &&
    css`
      color: currentColor;
    `}

  ${({ $hasContent }) =>
    $hasContent &&
    css`
      display: inline-flex;
      align-items: center;
    `}
`;

export const LinkContent = styled.div`
  & + & {
    margin-left: ${size(1)};
  }
`;
export const HashLink = styled(RRDHashLink)`
  ${linkBase}
`;
