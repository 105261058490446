import styled, { css } from 'styled-components';

import { size } from '../../layout/helpers';

interface WrapperProps {
  $tone?: 'main' | 'alt' | 'inverted';
  $size?: 'small' | 'large';
  $fullWidth?: boolean;
}

export const Wrapper = styled.div<WrapperProps>`
  max-width: ${(props) => props.theme.size.contentMaxWidth};
  font-size: ${(props) => props.theme.font.size.regular};

  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    display: block;
    margin-top: ${size(3)};
    margin-bottom: ${size(2)};
    font-weight: ${(props) => props.theme.font.weight.bold};
    line-height: 1.2;

    :first-child {
      margin-top: 0;
    }

    :last-child {
      margin-bottom: 0;
    }
  }

  h1 {
    font-size: ${(props) => props.theme.font.size.xxxlarge};
  }

  h2 {
    font-size: ${(props) => props.theme.font.size.xxlarge};
  }

  h3 {
    font-size: ${(props) => props.theme.font.size.xlarge};
  }

  h4 {
    font-size: ${(props) => props.theme.font.size.large};
  }

  h5 {
    font-size: ${(props) => props.theme.font.size.regular};
  }

  h6 {
    font-size: ${(props) => props.theme.font.size.small};
  }

  p {
    margin: ${size(2)} 0;

    :first-child {
      margin-top: 0;
    }

    :last-child {
      margin-bottom: 0;
    }
  }

  strong {
    font-weight: ${(props) => props.theme.font.weight.bold};
  }

  .table-wrapper {
    margin: ${size(2)} 0;
    width: calc(100vw - (${(props) => props.theme.size.containerGutter} * 2));
    max-width: calc(
      ${(props) => props.theme.size.containerMaxWidth} -
        (${(props) => props.theme.size.containerGutter} * 2)
    );
    overflow: auto;
    -webkit-overflow-scrolling: touch;

    ${(props) => props.theme.breakpoint.LtSm} {
      width: calc(100vw - (${(props) => props.theme.size.containerGutterLtSm} * 2));
    }

    :first-child {
      margin-top: 0;
    }

    :last-child {
      margin-bottom: 0;
    }

    table {
      margin: 0;
    }
  }

  table {
    width: 100%;
    margin: ${size(2)} 0;
    border-collapse: collapse;

    :first-child {
      margin-top: 0;
    }

    :last-child {
      margin-bottom: 0;
    }
  }

  th,
  td {
    padding: ${size(1)} ${size(2)};
    margin: 0;
    border: 1px solid ${(props) => props.theme.color.border.main};
    vertical-align: top;
  }

  th {
    background-color: ${(props) => props.theme.color.brand.secondary.main};
    color: white;
    font-weight: ${(props) => props.theme.font.weight.regular};
    text-align: left;
    white-space: nowrap;
  }

  ${({ $fullWidth }) =>
    $fullWidth &&
    css`
      max-width: 100%;
    `}

  ${({ $tone }) =>
    $tone === 'main'
      ? css`
          color: ${(props) => props.theme.color.text.main};
        `
      : $tone === 'alt'
      ? css`
          color: ${(props) => props.theme.color.text.alt};
        `
      : $tone === 'inverted'
      ? css`
          color: ${(props) => props.theme.color.text.inverted};
        `
      : css`
          color: currentColor;
        `}

  ${({ $size }) =>
    $size === 'small'
      ? css`
          font-size: ${(props) => props.theme.font.size.small};
        `
      : $size === 'large'
      ? css`
          font-size: ${(props) => props.theme.font.size.large};
        `
      : undefined}
`;
