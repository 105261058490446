interface DataCacheItem<T> {
  date: number;
  value: T;
}

const cacheTime = 60 * 1000;
const invalidateCacheCheck = 20 * 1000;

class DataCache<T> {
  cache: { [key: string]: DataCacheItem<T> | undefined } = {};
  interval: NodeJS.Timeout | undefined;

  startClearCacheInterval = () => {
    this.interval = setInterval(() => {
      const current = new Date().valueOf();
      const keys = Object.keys(this.cache);
      if (!keys.length && this.interval) {
        clearInterval(this.interval);
      }
      keys.forEach((k) => {
        if ((this.cache[k]?.date || 0) + cacheTime < current) {
          delete this.cache[k];
        }
      });
    }, invalidateCacheCheck);
  };

  getCachedValue = (key: string) => {
    const current = new Date().valueOf();
    if ((this.cache[key]?.date || 0) + cacheTime > current) {
      return this.cache[key]?.value;
    }
    delete this.cache[key];
    return undefined;
  };

  addToCache = (key: string, value: T) => {
    if (this.cache[key]) return;
    const current = new Date().valueOf();
    this.cache[key] = {
      date: current,
      value,
    };
    if (!this.interval) {
      this.startClearCacheInterval();
    }
  };
}

export default DataCache;
