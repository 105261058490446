import styled from 'styled-components';

import { size } from '../../layout/helpers';

export const Base = styled.div`
  display: flex;
  flex-direction: column;
  min-height: 100%;
`;

export const BaseHeader = styled.div`
  flex-shrink: 0;
  z-index: 80;
`;

export const BaseMain = styled.main`
  flex: 1 1 auto;
  display: flex;
  flex-direction: column;
  padding: ${size(8)} 0;
  background-color: ${(props) => props.theme.color.bg.main};

  ${(props) => props.theme.breakpoint.LtSm} {
    padding: ${size(4)} 0;
  }
`;

// Used to center the loader in BaseMain
export const BaseLoader = styled.div`
  flex: 1 1 auto;
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: 95vh;
`;

export const BaseFooter = styled.div`
  flex-shrink: 0;
  margin-top: auto;
`;
