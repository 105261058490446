import { NavigationItem } from './NavigationListItem';

/**
 * Is user allowed to view this Navigation Item?
 */
export function userCanView({
  item,
  userAdmin,
  clientAdmin,
  permissions,
}: {
  item: NavigationItem;
  userAdmin?: boolean;
  clientAdmin?: boolean;
  permissions?: string[];
}): boolean {
  // No restrictions
  if (!item.authority && !item.permission) {
    return true;
  }

  // Check authority
  if (item.authority) {
    if (userAdmin && item.authority.includes('userAdmin')) {
      return true;
    }
    if (clientAdmin && item.authority.includes('clientAdmin')) {
      return true;
    }
  }

  // Check permissions
  if (item.permission && permissions?.includes(item?.permission)) {
    return true;
  }

  return false;
}
