import styled from 'styled-components';

import { size } from '../../layout/helpers';

export const Wrapper = styled.div`
  padding: ${size(3)} 0;
  background-color: ${(props) => props.theme.color.brand.secondary.main};
  color: ${(props) => props.theme.color.text.inverted};

  ${(props) => props.theme.breakpoint.Sm} {
    position: sticky;
    top: 0;
    z-index: ${(props) => props.theme.zindex.stateBar};
  }
`;

export const Bar = styled.div`
  ${(props) => props.theme.breakpoint.Sm} {
    display: flex;
    align-items: center;
  }
`;

export const LeftContent = styled.div`
  ${(props) => props.theme.breakpoint.Sm} {
    flex: 1 1 auto;
  }
`;

export const RightContent = styled.div`
  ${(props) => props.theme.breakpoint.LtSm} {
    margin-top: ${size(2)};
  }

  ${(props) => props.theme.breakpoint.Sm} {
    flex-shrink: 0;
    padding-left: ${size(2)};
  }
`;
