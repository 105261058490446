import React, { lazy, Suspense } from 'react';
import { Route, RouteProps, Switch } from 'react-router-dom';

import { RouteItem } from '../@types/routes';
import { BaseLoader, BaseMain } from '../components/Base';
import Loader from '../components/Loader';
import useAuth from '../State/Auth/useAuth';
import { privateRoutes } from './private';
import { publicRoutes } from './public';
import Page from './Shared/Page';
import ScrollToTop from './ScrollToTop';

const RedirectToLogin = lazy(() => import('./RedirectToLogin'));

const Routes = () => {
  const { authenticated } = useAuth();

  return (
    <Suspense
      fallback={
        <BaseLoader>
          <Loader />
        </BaseLoader>
      }
    >
      <ScrollToTop />
      <Switch>
        {publicRoutes.map(({ component: Component, ...props }: RouteItem) => (
          <Route
            key={`public-${props.title}`}
            {...props}
            render={(propsComponent: RouteProps) => (
              <Page title={props.title}>
                <Component {...propsComponent} />
              </Page>
            )}
          />
        ))}

        {privateRoutes.map(({ component: Component, ...props }: RouteItem) =>
          authenticated ? (
            <Route
              key={`private-${props.title}`}
              {...props}
              render={(propsComponent: RouteProps) => (
                <BaseMain>
                  <Page title={props.title}>
                    <Component {...propsComponent} />
                  </Page>
                </BaseMain>
              )}
            />
          ) : (
            <RedirectToLogin key={`private-${props.title}`} />
          )
        )}
      </Switch>
    </Suspense>
  );
};

export default Routes;
