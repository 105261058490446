import React, { useCallback, useState } from 'react';
import Accordion from '..';

interface FilterSectionProps {
  defaultOpenFilter?: string;
  filterKey: string;
  title: string;
  isActive?: boolean;
  headElement?: React.ReactNode;
  children: React.ReactNode;
}

const AccordionWithAction = ({
  defaultOpenFilter,
  filterKey,
  title,
  isActive,
  headElement,
  children,
}: FilterSectionProps) => {
  const [isOpen, setIsOpen] = useState(defaultOpenFilter === filterKey);
  const onClick = useCallback(() => setIsOpen((f) => !f), []);

  return (
    <Accordion
      title={title}
      extended={isOpen}
      onClick={onClick}
      isActive={isActive}
      headElement={headElement}
    >
      {children}
    </Accordion>
  );
};

export default AccordionWithAction;
