import React, { useCallback, useEffect, useRef } from 'react';
import { Snackbar } from '../../State/Snackbar/SnackbarProvider';

import { Snack, Content, Action, Close } from './wrapper';
import { IconClose } from '../Icon';

const TIMEOUT = 10000;

interface SnackbarItemProps {
  snackbar: Snackbar;
  removeSnackbar: (id: number) => void;
  transform: (height: number) => void;
  disableRemove?: boolean;
  fixedTop?: boolean;
  fixedBottom?: boolean;
}

const SnackbarItem = ({
  snackbar,
  removeSnackbar,
  transform,
  disableRemove,
  fixedTop,
  fixedBottom,
}: SnackbarItemProps) => {
  const onClick = useCallback(() => removeSnackbar(snackbar.id), []);
  const ref = useRef<HTMLDivElement | null>(null);

  if (!disableRemove) {
    useEffect(() => {
      setTimeout(() => {
        removeSnackbar(snackbar.id);
      }, TIMEOUT);

      setTimeout(() => {
        if (ref.current?.clientHeight) {
          transform(ref.current.clientHeight);
        }
      }, TIMEOUT - 300);

      setTimeout(() => {
        if (ref.current) {
          ref.current.style.opacity = '0';
          ref.current.style.transition = 'opacity 300ms ease';
        }
      }, TIMEOUT - 600);

      return () => {
        removeSnackbar(snackbar.id);
      };
    }, []);
  }

  return (
    <Snack
      $type={snackbar.type as 'error' | 'success' | 'danger' | 'warning'}
      $fixedTop={fixedTop || false}
      $fixedBottom={fixedBottom || false}
      ref={ref}
    >
      <Content>{snackbar.reactNode}</Content>
      <Action>
        <Close onClick={onClick}>
          <IconClose />
        </Close>
      </Action>
    </Snack>
  );
};

export default SnackbarItem;
