import { createContext } from 'react';
import { Snackbar, SnackbarBase } from './SnackbarProvider';

export interface SnackbarContext {
  snackbars: Snackbar[];
  removeSnackbar: (id: number) => void;
  pushSnackbar: (snackbar: SnackbarBase) => void;
  pushSnackbarSuccess: (reactNode: React.ReactNode) => void;
  pushSnackbarError: (reactNode: React.ReactNode) => void;
}

export const SnackbarContext = createContext<SnackbarContext | undefined>(undefined);
