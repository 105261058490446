import React, { useMemo, useCallback, useReducer, useRef } from 'react';

import * as http from '../../http';
import { buildAuthorizedGetRequest } from '../../http/request-templates';
import { ProfileContext } from './ProfileContext';
import { Profile } from './ProfileModels';

import profileReducer, {
  ERR_GET_PROFILE,
  profileReducerInitialState,
  RCV_GET_PROFILE,
  REQ_GET_PROFILE,
} from './profileReducer';
import useAuth from '../Auth/useAuth';

interface ProfileProviderProps {
  children: React.ReactNode;
}

const ProfileProvider = ({ children }: ProfileProviderProps) => {
  const { token } = useAuth();
  const tokenRef = useRef(token);
  tokenRef.current = token;

  const [profileState, dispatch] = useReducer(profileReducer, profileReducerInitialState);

  const getProfile = useCallback(async () => {
    if (!tokenRef.current) {
      throw new Error('Missing token');
    }

    try {
      dispatch({ type: REQ_GET_PROFILE });
      const response = await http.rawJson<Profile>(
        `${process.env.CUSTOMER_PORTAL_API}/profile`,
        buildAuthorizedGetRequest(tokenRef.current)
      );
      dispatch({ type: RCV_GET_PROFILE, profile: response });
    } catch (e) {
      dispatch({ type: ERR_GET_PROFILE });
    }
  }, []);

  const value = useMemo(
    () => ({
      ...profileState,
      getProfile,
    }),
    [profileState]
  );

  return <ProfileContext.Provider value={value}>{children}</ProfileContext.Provider>;
};

export default ProfileProvider;
