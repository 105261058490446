import React, { useMemo, useCallback, useReducer, useRef } from 'react';

import {
  buildAuthorizedDeleteRequest,
  buildAuthorizedGetRequest,
  buildAuthorizedPostRequest,
  buildAuthorizedPutRequest,
} from '../../http/request-templates';
import { UsersContext } from './UsersContext';
import usersReducer, {
  ERR_ADMIN_GET_PAYER,
  RCV_ADMIN_GET_PAYER,
  REQ_ADMIN_GET_PAYER,
  REQ_CLIENT_GET_USERS,
  RCV_CLIENT_GET_USERS,
  ERR_CLIENT_GET_USERS,
  REQ_CLIENT_GET_USER,
  RCV_CLIENT_GET_USER,
  ERR_CLIENT_GET_USER,
  REQ_CLIENT_UPDATE_USER,
  RCV_CLIENT_UPDATE_USER,
  ERR_CLIENT_UPDATE_USER,
  REQ_ADMIN_GET_USER,
  RCV_ADMIN_GET_USER,
  ERR_ADMIN_GET_USER,
  REQ_ADMIN_CREATE_CLIENT_ADMIN,
  RCV_ADMIN_CREATE_CLIENT_ADMIN,
  ERR_ADMIN_CREATE_CLIENT_ADMIN,
  REQ_ADMIN_UPDATE_CLIENT_ADMIN,
  RCV_ADMIN_UPDATE_CLIENT_ADMIN,
  ERR_ADMIN_UPDATE_CLIENT_ADMIN,
  REQ_ADMIN_REMOVE_USER,
  RCV_ADMIN_REMOVE_USER,
  ERR_ADMIN_REMOVE_USER,
  REQ_ADMIN_RESET_PASSWORD,
  RCV_ADMIN_RESET_PASSWORD,
  ERR_ADMIN_RESET_PASSWORD,
  REQ_ADMIN_ENABLE_DISABLE,
  RCV_ADMIN_ENABLE_DISABLE,
  ERR_ADMIN_ENABLE_DISABLE,
  CLEAR_USER,
  usersReducerInitialState,
  REQ_CLIENT_CREATE_USER,
  RCV_CLIENT_CREATE_USER,
  ERR_CLIENT_CREATE_USER,
  REQ_CLIENT_RESET_PASSWORD,
  RCV_CLIENT_RESET_PASSWORD,
  ERR_CLIENT_RESET_PASSWORD,
  REQ_CLIENT_ENABLE_DISABLE,
  RCV_CLIENT_ENABLE_DISABLE,
  ERR_CLIENT_ENABLE_DISABLE,
  REQ_ADMIN_SEARCH,
  RCV_ADMIN_SEARCH,
  ERR_ADMIN_SEARCH,
} from './usersReducer';
import useAuth from '../Auth/useAuth';
import {
  ClientCreateUser,
  CreateUser,
  Payer,
  PayerUser,
  User,
  AdminSearchResult,
} from './UsersModels';
import useHttp from '../../http/useHttp';
import { useTranslation } from 'react-i18next';

interface UsersProviderProps {
  children: React.ReactNode;
}

const UsersProvider = ({ children }: UsersProviderProps) => {
  const { token } = useAuth();
  const { httpRawJson } = useHttp();
  const { t } = useTranslation();
  const tokenRef = useRef(token);
  tokenRef.current = token;

  const [usersState, dispatch] = useReducer(usersReducer, usersReducerInitialState);

  const adminGetPayer = useCallback(async (id: string) => {
    if (!tokenRef.current) {
      throw new Error('Missing token');
    }

    try {
      dispatch({ type: REQ_ADMIN_GET_PAYER });
      const response = await httpRawJson<Payer>(
        `${process.env.CUSTOMER_PORTAL_API}/admin/payers/${id}/users`,
        buildAuthorizedGetRequest(tokenRef.current)
      );
      dispatch({ type: RCV_ADMIN_GET_PAYER, payer: { ...response, customer_number: id } });
    } catch (e) {
      dispatch({ type: ERR_ADMIN_GET_PAYER });
    }
  }, []);

  const clientGetUsers = useCallback(async () => {
    if (!tokenRef.current) {
      throw new Error('Missing token');
    }

    try {
      dispatch({ type: REQ_CLIENT_GET_USERS });
      const response = await httpRawJson<PayerUser[]>(
        `${process.env.CUSTOMER_PORTAL_API}/client/users`,
        buildAuthorizedGetRequest(tokenRef.current)
      );
      dispatch({ type: RCV_CLIENT_GET_USERS, users: response });
    } catch (e) {
      dispatch({ type: ERR_CLIENT_GET_USERS });
    }
  }, []);

  const clientGetUser = useCallback(async (email: string) => {
    if (!tokenRef.current) {
      throw new Error('Missing token');
    }

    try {
      dispatch({ type: REQ_CLIENT_GET_USER });
      const response = await httpRawJson<User>(
        `${process.env.CUSTOMER_PORTAL_API}/client/users/${email}`,
        buildAuthorizedGetRequest(tokenRef.current)
      );
      dispatch({ type: RCV_CLIENT_GET_USER, user: response });
    } catch (e) {
      dispatch({ type: ERR_CLIENT_GET_USER });
    }
  }, []);

  const clientResetPassword = useCallback(async (email: string) => {
    if (!tokenRef.current) {
      throw new Error('Missing token');
    }

    try {
      dispatch({ type: REQ_CLIENT_RESET_PASSWORD });
      await httpRawJson(
        `${process.env.CUSTOMER_PORTAL_API}/client/users/${email}/reset-password`,
        buildAuthorizedPutRequest(tokenRef.current),
        {
          snackbarOptions: {
            onError: <>{t('users_reset_password_error', { email })}</>,
            onSuccess: <>{t('users_reset_password_success', { email })}</>,
          },
        }
      );
      dispatch({ type: RCV_CLIENT_RESET_PASSWORD });
    } catch (e) {
      dispatch({ type: ERR_CLIENT_RESET_PASSWORD });
    }
  }, []);

  const clientEnableDisableUser = useCallback(async (email: string, enable: boolean) => {
    if (!tokenRef.current) {
      throw new Error('Missing token');
    }

    try {
      dispatch({ type: REQ_CLIENT_ENABLE_DISABLE });
      await httpRawJson(
        `${process.env.CUSTOMER_PORTAL_API}/client/users/${email}/${enable ? 'enable' : 'disable'}`,
        buildAuthorizedPutRequest(tokenRef.current),
        {
          snackbarOptions: {
            onError: <>{t(enable ? 'users_enable_error' : 'users_disable_error', { email })}</>,
            onSuccess: (
              <>{t(enable ? 'users_enable_success' : 'users_disable_success', { email })}</>
            ),
          },
        }
      );
      dispatch({ type: RCV_CLIENT_ENABLE_DISABLE, email, enable });
    } catch (e) {
      dispatch({ type: ERR_CLIENT_ENABLE_DISABLE });
    }
  }, []);

  const adminGetUser = useCallback(async (id: string, email: string) => {
    if (!tokenRef.current) {
      throw new Error('Missing token');
    }

    try {
      dispatch({ type: REQ_ADMIN_GET_USER });
      const response = await httpRawJson<User>(
        `${process.env.CUSTOMER_PORTAL_API}/admin/payers/${id}/users/${email}`,
        buildAuthorizedGetRequest(tokenRef.current)
      );
      dispatch({ type: RCV_ADMIN_GET_USER, user: response });
    } catch (e) {
      dispatch({ type: ERR_ADMIN_GET_USER });
    }
  }, []);

  const adminCreateUser = useCallback(async (id: string, payload: CreateUser) => {
    if (!tokenRef.current) {
      throw new Error('Missing token');
    }

    try {
      dispatch({ type: REQ_ADMIN_CREATE_CLIENT_ADMIN });
      await httpRawJson(
        `${process.env.CUSTOMER_PORTAL_API}/admin/payers/${id}/users`,
        buildAuthorizedPostRequest(tokenRef.current, payload)
      );
      dispatch({ type: RCV_ADMIN_CREATE_CLIENT_ADMIN, user: payload });
    } catch (e) {
      dispatch({ type: ERR_ADMIN_CREATE_CLIENT_ADMIN });
    }
  }, []);

  const clientCreateUser = useCallback(async (payload: ClientCreateUser) => {
    if (!tokenRef.current) {
      throw new Error('Missing token');
    }

    try {
      dispatch({ type: REQ_CLIENT_CREATE_USER });
      await httpRawJson(
        `${process.env.CUSTOMER_PORTAL_API}/client/users`,
        buildAuthorizedPostRequest(tokenRef.current, payload)
      );
      dispatch({ type: RCV_CLIENT_CREATE_USER, user: payload });
    } catch (e) {
      dispatch({ type: ERR_CLIENT_CREATE_USER });
    }
  }, []);

  const adminUpdateUser = useCallback(async (payload: CreateUser) => {
    if (!tokenRef.current) {
      throw new Error('Missing token');
    }

    try {
      dispatch({ type: REQ_ADMIN_UPDATE_CLIENT_ADMIN });
      await httpRawJson<undefined>(
        `${process.env.CUSTOMER_PORTAL_API}/admin/users/${payload.email}`,
        buildAuthorizedPutRequest(tokenRef.current, payload)
      );
      dispatch({ type: RCV_ADMIN_UPDATE_CLIENT_ADMIN, user: payload });
    } catch (e) {
      dispatch({ type: ERR_ADMIN_UPDATE_CLIENT_ADMIN });
    }
  }, []);

  const clientUpdateUser = useCallback(async (payload: ClientCreateUser) => {
    if (!tokenRef.current) {
      throw new Error('Missing token');
    }

    try {
      dispatch({ type: REQ_CLIENT_UPDATE_USER });
      await httpRawJson<undefined>(
        `${process.env.CUSTOMER_PORTAL_API}/client/users/${payload.email}`,
        buildAuthorizedPutRequest(tokenRef.current, payload)
      );

      const user = await httpRawJson<User>(
        `${process.env.CUSTOMER_PORTAL_API}/client/users/${payload.email}`,
        buildAuthorizedGetRequest(tokenRef.current)
      );

      dispatch({ type: RCV_CLIENT_UPDATE_USER, user });
    } catch (e) {
      dispatch({ type: ERR_CLIENT_UPDATE_USER });
    }
  }, []);

  const adminRemoveUser = useCallback(async (id: string, email: string) => {
    if (!tokenRef.current) {
      throw new Error('Missing token');
    }
    try {
      dispatch({ type: REQ_ADMIN_REMOVE_USER });
      await httpRawJson(
        `${process.env.CUSTOMER_PORTAL_API}/admin/users/${email}`,
        buildAuthorizedDeleteRequest(tokenRef.current),
        {
          snackbarOptions: {
            onError: <>{t('users_remove_user_error', { email })}</>,
            onSuccess: <>{t('users_remove_user_success', { email })}</>,
          },
        }
      );
      dispatch({ type: RCV_ADMIN_REMOVE_USER, email });
    } catch (e) {
      dispatch({ type: ERR_ADMIN_REMOVE_USER });
    }
  }, []);

  const adminResetPassword = useCallback(async (email: string) => {
    if (!tokenRef.current) {
      throw new Error('Missing token');
    }

    try {
      dispatch({ type: REQ_ADMIN_RESET_PASSWORD });
      await httpRawJson(
        `${process.env.CUSTOMER_PORTAL_API}/admin/users/${email}/reset-password`,
        buildAuthorizedPutRequest(tokenRef.current),
        {
          snackbarOptions: {
            onError: <>{t('users_reset_password_error', { email })}</>,
            onSuccess: <>{t('users_reset_password_success', { email })}</>,
          },
        }
      );
      dispatch({ type: RCV_ADMIN_RESET_PASSWORD });
    } catch (e) {
      dispatch({ type: ERR_ADMIN_RESET_PASSWORD });
    }
  }, []);

  const adminEnableDisableUser = useCallback(async (email: string, enable: boolean) => {
    if (!tokenRef.current) {
      throw new Error('Missing token');
    }

    try {
      dispatch({ type: REQ_ADMIN_ENABLE_DISABLE });
      await httpRawJson(
        `${process.env.CUSTOMER_PORTAL_API}/admin/users/${email}/${enable ? 'enable' : 'disable'}`,
        buildAuthorizedPutRequest(tokenRef.current),
        {
          snackbarOptions: {
            onError: <>{t(enable ? 'users_enable_error' : 'users_disable_error', { email })}</>,
            onSuccess: (
              <>{t(enable ? 'users_enable_success' : 'users_disable_success', { email })}</>
            ),
          },
        }
      );
      dispatch({ type: RCV_ADMIN_ENABLE_DISABLE, email, enable });
    } catch (e) {
      dispatch({ type: ERR_ADMIN_ENABLE_DISABLE });
    }
  }, []);

  const adminSearch = useCallback(async (query: string) => {
    if (!tokenRef.current) {
      throw new Error('Missing token');
    }

    try {
      dispatch({ type: REQ_ADMIN_SEARCH });
      const response = await httpRawJson<AdminSearchResult[]>(
        `${process.env.CUSTOMER_PORTAL_API}/admin/search?search=${query}`,
        buildAuthorizedGetRequest(tokenRef.current)
      );
      dispatch({ type: RCV_ADMIN_SEARCH, adminSearchResult: response });
    } catch (e) {
      dispatch({ type: ERR_ADMIN_SEARCH });
    }
  }, []);

  const clearUser = useCallback(() => dispatch({ type: CLEAR_USER }), []);

  const value = useMemo(
    () => ({
      ...usersState,
      adminGetPayer,
      adminGetUser,
      adminCreateUser,
      adminUpdateUser,
      adminRemoveUser,
      adminResetPassword,
      adminEnableDisableUser,
      clearUser,
      clientGetUsers,
      clientGetUser,
      clientCreateUser,
      clientUpdateUser,
      clientResetPassword,
      clientEnableDisableUser,
      adminSearch,
    }),
    [usersState]
  );

  return <UsersContext.Provider value={value}>{children}</UsersContext.Provider>;
};

export default UsersProvider;
