import React from 'react';

import {
  Group,
  Wrapper,
  Header,
  HeaderIcon,
  HeaderTitle,
  HeaderTitleText,
  HeaderTitleIndicator,
  Body,
} from './wrapper';
import { IconDirDown, IconDirUp } from '../Icon';

export interface IAccordionGroup {
  children: React.ReactNode;
}

export const AccordionGroup = ({ children }: IAccordionGroup) => <Group>{children}</Group>;

export interface IAccordion {
  children: React.ReactNode;
  title: string;
  onClick: () => void;
  extended?: boolean;
  isActive?: boolean;
  boxed?: boolean;
  headElement?: React.ReactNode;
}

const Accordion = ({
  children,
  title,
  onClick,
  extended,
  isActive,
  boxed,
  headElement,
}: IAccordion) => (
  <Wrapper $boxed={boxed}>
    <Header onClick={onClick} title={title}>
      <HeaderTitle>
        <HeaderTitleText>{title}</HeaderTitleText>

        {isActive && <HeaderTitleIndicator />}
      </HeaderTitle>
      {headElement}
      <HeaderIcon>{extended ? <IconDirUp /> : <IconDirDown />}</HeaderIcon>
    </Header>
    {extended && <Body>{children}</Body>}
  </Wrapper>
);

export default Accordion;
