import { matchPath } from 'react-router-dom';

import * as Sentry from '@sentry/react';
import { BrowserOptions } from '@sentry/browser';
import { BrowserTracing } from '@sentry/tracing';

import { createBrowserHistory } from 'history';

import { publicRoutes } from './routes/public';

// This is duplicated from webpack.config.js
function getSentryEnv(env?: string): string {
  switch (env) {
    case 'production':
      return 'prd';

    case 'test':
      return 'tst';

    case 'development':
      return 'dev';

    default:
      return 'dev';
  }
}

const history = createBrowserHistory();
const routes = [...publicRoutes, ...publicRoutes].map((route) => {
  return {
    path: route.path,
    title: route.title,
  };
});

export const sentryConfig: BrowserOptions = {
  dsn: 'https://ebc6d51391e345a6b6b32f927874cf57@o1176871.ingest.sentry.io/6275258',
  environment: getSentryEnv(process.env.NODE_ENV),
  integrations: [
    new BrowserTracing({
      tracingOrigins: ['api.dev.rmdse.se', 'api.tst.rmdse.se', 'api.rmdse.se'],
      routingInstrumentation: Sentry.reactRouterV5Instrumentation(history, routes, matchPath),
    }),
  ],
  tracesSampleRate: 1.0,
  // We can modify the event useing beforeSend(event) here
  // https://docs.sentry.io/platforms/javascript/configuration/filtering/#using-platformidentifier-namebefore-send-
};
