import styled, { css } from 'styled-components';

import { size, rgba } from '../../layout/helpers';
import { ButtonReset } from '../../layout/reset';
import { Container } from '../Container';
import { fadeIn } from '../../layout/keyframes';
import { Link as RRDLink } from 'react-router-dom';

import {
  Header as PanelHeader,
  Heading as PanelHeading,
  Close as PanelClose,
  CloseBtn as PanelCloseBtn,
} from '../Panel/wrapper';

const headerHeight = 15;

export const Wrapper = styled.header`
  position: relative;

  color: ${(props) => props.theme.color.text.main};
  z-index: 1;

  ${(props) => props.theme.breakpoint.LtMd} {
    padding: 1rem;
  }

  ${(props) => props.theme.breakpoint.Md} {
    height: ${size(headerHeight)};
    background-color: ${(props) => props.theme.color.bg.alt};
  }
`;

export const Inner = styled.div`
  display: flex;
  align-items: center;
`;

export const Main = styled.div`
  flex: 1 1 auto;
  z-index: 1;
`;

export const TopNav = styled.div`
  width: 100%;
  display: contents;
  background-color: ${(props) => props.theme.color.bg.alt};
  position: relative;
  z-index: 1;
  height: 100%;
  align-items: center;

  ${(props) => props.theme.breakpoint.Md} {
    display: flex;
  }
`;

export const MainInnerBackdrop = styled.div.attrs(() => ({
  'aria-hidden': 'true',
  role: 'button',
}))`
  display: none;

  ${(props) => props.theme.breakpoint.LtMd} {
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background-color: rgba(0, 0, 0, 0.35);
    animation: ${fadeIn} 200ms ease;
    z-index: 1;

    @media (prefers-reduced-motion) {
      animation: none;
    }
  }
`;

export const MainInnerDialog = styled.div`
  ${(props) => props.theme.breakpoint.LtMd} {
    position: fixed;
    top: 0;
    bottom: 0;
    right: 100%;
    width: calc(100% - ${size(3)});
    max-width: 520px;
    display: flex;
    flex-direction: column;
    background-color: ${(props) => props.theme.color.bg.alt};
    transition: transform 200ms ease;
    z-index: 2;

    @media (prefers-reduced-motion) {
      transition-duration: 0s;
    }
  }
`;

interface MainInnerProps {
  $isOpen?: boolean;
}

export const MainInner = styled.div<MainInnerProps>`
  ${(props) => props.theme.breakpoint.LtMd} {
    z-index: ${(props) => props.theme.zindex.mobileMenu};

    ${({ $isOpen }) =>
      $isOpen &&
      css`
        ${MainInnerBackdrop} {
          display: block;
        }

        ${MainInnerDialog} {
          transform: translateX(100%);
        }
      `}
  }
`;

export const MenuBtn = styled(ButtonReset)`
  position: relative;
  display: block;
  font-size: ${(props) => props.theme.font.size.xlarge};

  // Ghost element
  &:before {
    content: '';
    position: absolute;
    top: 50%;
    left: 50%;
    width: ${size(5)};
    height: ${size(5)};
    transform: translate(-50%, -50%);
  }

  ${(props) => props.theme.breakpoint.Md} {
    display: none;
  }
`;

export const NavHeader = styled(PanelHeader)`
  ${(props) => props.theme.breakpoint.Md} {
    display: none;
  }
`;

export const NavHeading = styled(PanelHeading)``;

export const NavClose = styled(PanelClose)``;

export const NavCloseBtn = styled(PanelCloseBtn)``;

export const NavBody = styled.div`
  ${(props) => props.theme.breakpoint.LtMd} {
    flex: 1 1 auto;
    display: flex;
    flex-direction: column;
    overflow: auto;
    -webkit-overflow-scrolling: touch;
    padding: ${size(6)} ${size(3)};
    gap: ${size(6)};
  }

  ${(props) => props.theme.breakpoint.Md} {
    display: flex;
    align-items: center;
  }
`;

export const Logo = styled.div`
  flex-shrink: 0;
  width: 155px;

  ${(props) => props.theme.breakpoint.LtMd} {
    order: 10;
    padding-top: ${size(3)};
    margin-top: 0;
    padding-top: 0;
    max-width: 130px;
  }

  ${(props) => props.theme.breakpoint.Md} {
    margin-right: ${size(9)};
  }
`;

export const LogoImg = styled.img`
  display: block;
  width: 100%;
`;

export const Nav = styled.div``;

export const NavList = styled.ul`
  list-style: none;
  padding: 0;
  margin: 0;

  ${(props) => props.theme.breakpoint.LtMd} {
    margin: ${size(-2)} 0;
  }

  ${(props) => props.theme.breakpoint.Md} {
    display: flex;
    align-items: center;
    margin: ${size(-2)};
  }
`;

export const NavItem = styled.li``;

interface NavLinkProps {
  $isActive?: boolean;
}

export const NavLink = styled(RRDLink)<NavLinkProps>`
  display: flex;
  align-items: center;
  color: ${(props) => props.theme.color.text.main};
  font-weight: ${(props) => props.theme.font.weight.bold};
  text-decoration: none;
  font-size: ${(props) => props.theme.font.size.large};

  ${({ $isActive }) =>
    $isActive &&
    css`
      //color: ${(props) => props.theme.color.brand.primary.main};
    `}

  ${(props) => props.theme.breakpoint.LtMd} {
    padding: ${size(2)} 0;
  }

  ${(props) => props.theme.breakpoint.Md} {
    position: relative;
    height: ${size(headerHeight / 3)};
    padding: 0 ${size(2)};

    // Ghost element
    &:before {
      content: '';
      position: absolute;
      top: ${size((headerHeight / 3) * -1)};
      bottom: ${size((headerHeight / 3) * -1)};
      left: 0;
      right: 0;
    }

    &:hover {
      color: ${(props) => props.theme.color.brand.primary.main};
    }

    ${({ $isActive }) =>
      $isActive &&
      css`
        &:after {
          content: '';
          display: block;
          position: absolute;
          bottom: ${size((headerHeight / 3) * -1)};
          left: ${size(2)};
          right: ${size(2)};
          height: ${size(0.5)};
          background-color: ${(props) => props.theme.color.brand.primary.main};
        }
      `}
  }
`;

export const NavLinkTitle = styled.div`
  ${(props) => props.theme.breakpoint.LtMd} {
    flex: 1 1 auto;
  }
`;

export const NavLinkIcon = styled.div`
  ${(props) => props.theme.breakpoint.LtMd} {
    flex-shrink: 0;
    padding-left: ${size(2)};
    color: ${(props) => props.theme.color.text.main};
  }

  ${(props) => props.theme.breakpoint.Md} {
    display: none;
  }
`;

export const NavAction = styled(ButtonReset)<NavLinkProps>`
  display: flex;
  align-items: center;
  color: ${(props) => props.theme.color.text.main};
  font-weight: ${(props) => props.theme.font.weight.bold};
  text-decoration: none;
  font-size: ${(props) => props.theme.font.size.large};

  ${({ $isActive }) =>
    $isActive &&
    css`
      color: ${(props) => props.theme.color.brand.primary.main};
    `}

  ${(props) => props.theme.breakpoint.LtMd} {
    width: 100%;
    padding: ${size(2)} 0;
    text-align: left;
  }

  ${(props) => props.theme.breakpoint.Md} {
    position: relative;
    height: ${size(headerHeight / 3)};
    padding: 0 ${size(2)};

    // Ghost element
    &:before {
      content: '';
      position: absolute;
      top: ${size((headerHeight / 3) * -1)};
      bottom: ${size((headerHeight / 3) * -1)};
      left: 0;
      right: 0;
    }

    &:hover {
      color: ${(props) => props.theme.color.brand.primary.main};
    }

    ${({ $isActive }) =>
      $isActive &&
      css`
        &:after {
          content: '';
          display: block;
          position: absolute;
          bottom: ${size((headerHeight / 3) * -1)};
          left: ${size(2)};
          right: ${size(2)};
          height: ${size(0.5)};
          background-color: ${(props) => props.theme.color.brand.primary.main};
        }
      `}
  }
`;

export const NavActionTitle = styled.div`
  ${(props) => props.theme.breakpoint.LtMd} {
    flex: 1 1 auto;
  }
`;

export const NavActionIcon = styled.div`
  ${(props) => props.theme.breakpoint.LtMd} {
    flex-shrink: 0;
    padding-left: ${size(2)};
    color: ${(props) => props.theme.color.text.main};
  }

  ${(props) => props.theme.breakpoint.Md} {
    padding-left: ${size(1)};
    font-size: 1rem;
  }
`;

export const SubNav = styled.div`
  border-top: 1px solid ${(props) => props.theme.color.border.main};

  ${(props) => props.theme.breakpoint.LtMd} {
    border-top: 0;

    ${Container} {
      padding: 0;
    }

    h2 {
      display: none;
    }
  }

  ${(props) => props.theme.breakpoint.Md} {
    padding-top: ${size(7)};
    z-index: 100;

    position: absolute;
    top: 100%;
    left: 0;
    right: 0;
    background: ${(props) => props.theme.color.bg.alt};
    box-shadow: 0 2px 4px ${(props) => rgba(props.theme.color.brand.secondary.main, 0.1)};
  }
`;

export const Backdrop = styled.div.attrs(() => ({
  'aria-hidden': 'true',
  role: 'button',
}))`
  position: fixed;
  inset: 0 0 0 0;
  z-index: -1;
  background: ${(props) => rgba(props.theme.color.brand.secondary.main, 0.25)};
  animation: ${fadeIn} 200ms ease;

  @media (prefers-reduced-motion) {
    animation: none;
  }
`;

export const SubNavFooter = styled.div`
  display: flex;
  justify-content: space-between;
  border-top: 1px solid ${(props) => props.theme.color.border.main};
  padding-block: ${size(3)} ${size(6)};

  ${(props) => props.theme.breakpoint.LtMd} {
    display: none;
  }
`;

export const SubNavWidgets = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  min-height: 100%;
  width: 100%;
  max-width: 500px;
  justify-self: end;
  gap: ${size(2)};
`;

export const SubNavGrid = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(min(300px, 100%), 1fr));
  gap: ${size(2)};

  ${(props) => props.theme.breakpoint.Md} {
    padding-block: ${size(5)};
    gap: ${size(5)};
  }

  ${(props) => props.theme.breakpoint.LtMd} {
    ${SubNavWidgets} {
      display: none;
    }
  }
`;

export const SubNavList = styled.ul`
  list-style: none;
  padding: 0;
  margin: 0;
  display: flex;
  flex-direction: column;
  grid-auto-flow: row;
  gap: ${size(5)};
  max-width: 620px;
  align-items: flex-start;

  ${(props) => props.theme.breakpoint.LtMd} {
    gap: 0;
    padding-left: ${size(2)};
  }
`;

interface SubNavLinkProps {
  $isActive?: boolean;
}

export const SubNavLink = styled(RRDLink)<SubNavLinkProps>`
  --title-color: ${(props) => props.theme.color.text.main};

  display: grid;
  grid-template-columns: 80px 1fr;
  column-gap: ${size(2)};
  align-items: center;
  text-decoration: none;
  color: ${(props) => props.theme.color.text.main};

  &:hover .title {
    --title-color: ${(props) => props.theme.color.brand.primary.main};
  }

  ${({ $isActive }) =>
    $isActive &&
    css`
      --title-color: ${(props) => props.theme.color.brand.primary.main};
    `}

  ${(props) => props.theme.breakpoint.LtMd} {
    padding: ${size(2)} 0;
  }

  aside {
    width: 100%;
    aspect-ratio: 1/1;
    border-radius: 5px;
    background: ${(props) => props.theme.color.bg.main};
    display: grid;
    place-items: center;
  }

  .description {
    display: flex;
    flex-direction: column;
    justify-content: center;
  }

  .title {
    font-weight: 700;
    color: var(--title-color);
  }

  ${(props) => props.theme.breakpoint.LtMd} {
    grid-template-columns: 1fr;

    aside {
      display: none;
    }

    .description > :not(.title) {
      display: none;
    }
  }
`;

export const Profile = styled.div`
  flex-shrink: 0;
  padding-left: ${size(4)};
  position: relative;
`;

export const ProfileBtn = styled(ButtonReset)`
  display: flex;
  align-items: center;
`;

export const ProfileAvatar = styled.div`
  position: relative;
  flex-shrink: 0;

  &:before {
    content: '';
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    border-radius: 50%;
    box-shadow: 0 2px 4px ${(props) => rgba(props.theme.color.brand.secondary.main, 0.3)};
    opacity: 0;
    transition: opacity 150ms ease;
  }

  ${ProfileBtn}:hover & {
    &:before {
      opacity: 1;
    }
  }
`;

export const ProfileIcon = styled.div`
  flex-shrink: 0;
  padding-left: ${size(1)};
`;
