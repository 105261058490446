import useDocumentTitle from '../../hooks/useDocumentTitle';
import i18n from '../../i18n';

interface PageProps {
  title: string;
  children: JSX.Element;
}

const Page = ({ title, children }: PageProps): JSX.Element => {
  useDocumentTitle(`${title} - ${i18n.t('title_base')}`);
  return children;
};

export default Page;
