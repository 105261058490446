import cloneDeep from 'lodash.clonedeep';
import { BaseAction } from '../../@types/Action';
import {
  RequestState,
  requestInit,
  requestSuccessful,
  requestError,
  requestIdle,
} from '../../http/requestState';
import { GraphReport, SavedGraphFilter } from './GraphModels';

export const REQ_GET_GRAPH_REPORT = 'REQ_GET_GRAPH_REPORT';
export const RCV_GET_GRAPH_REPORT = 'RCV_GET_GRAPH_REPORT';
export const ERR_GET_GRAPH_REPORT = 'ERR_GET_GRAPH_REPORT';

type REQ_GET_GRAPH_REPORT_TYPE = BaseAction<typeof REQ_GET_GRAPH_REPORT>;
type RCV_GET_GRAPH_REPORT_TYPE = BaseAction<typeof RCV_GET_GRAPH_REPORT> & {
  graphReport: GraphReport;
};
type ERR_GET_GRAPH_REPORT_TYPE = BaseAction<typeof ERR_GET_GRAPH_REPORT>;

export const REQ_GET_GRAPH_REPORT_COMPARE = 'REQ_GET_GRAPH_REPORT_COMPARE';
export const RCV_GET_GRAPH_REPORT_COMPARE = 'RCV_GET_GRAPH_REPORT_COMPARE';
export const ERR_GET_GRAPH_REPORT_COMPARE = 'ERR_GET_GRAPH_REPORT_COMPARE';

type REQ_GET_GRAPH_REPORT_COMPARE_TYPE = BaseAction<typeof REQ_GET_GRAPH_REPORT_COMPARE>;
type RCV_GET_GRAPH_REPORT_COMPARE_TYPE = BaseAction<typeof RCV_GET_GRAPH_REPORT_COMPARE> & {
  graphReport: GraphReport;
};
type ERR_GET_GRAPH_REPORT_COMPARE_TYPE = BaseAction<typeof ERR_GET_GRAPH_REPORT_COMPARE>;

export const REQ_GET_SAVED_FILTERS = 'REQ_GET_SAVED_FILTERS';
export const RCV_GET_SAVED_FILTERS = 'RCV_GET_SAVED_FILTERS';
export const ERR_GET_SAVED_FILTERS = 'ERR_GET_SAVED_FILTERS';
type REQ_GET_SAVED_FILTERS_TYPE = BaseAction<typeof REQ_GET_SAVED_FILTERS>;
type RCV_GET_SAVED_FILTERS_TYPE = BaseAction<typeof RCV_GET_SAVED_FILTERS> & {
  savedFilters: SavedGraphFilter[];
};
type ERR_GET_SAVED_FILTERS_TYPE = BaseAction<typeof ERR_GET_SAVED_FILTERS>;

export const REQ_CREATE_SAVED_FILTERS = 'REQ_CREATE_SAVED_FILTERS';
export const RCV_CREATE_SAVED_FILTERS = 'RCV_CREATE_SAVED_FILTERS';
export const ERR_CREATE_SAVED_FILTERS = 'ERR_CREATE_SAVED_FILTERS';
type REQ_CREATE_SAVED_FILTERS_TYPE = BaseAction<typeof REQ_CREATE_SAVED_FILTERS>;
type RCV_CREATE_SAVED_FILTERS_TYPE = BaseAction<typeof RCV_CREATE_SAVED_FILTERS> & {
  savedFilter: SavedGraphFilter;
};
type ERR_CREATE_SAVED_FILTERS_TYPE = BaseAction<typeof ERR_CREATE_SAVED_FILTERS>;

export const REQ_REMOVE_SAVED_FILTER = 'REQ_REMOVE_SAVED_FILTER';
export const RCV_REMOVE_SAVED_FILTER = 'RCV_REMOVE_SAVED_FILTER';
export const ERR_REMOVE_SAVED_FILTER = 'ERR_REMOVE_SAVED_FILTER';
type REQ_REMOVE_SAVED_FILTER_TYPE = BaseAction<typeof REQ_REMOVE_SAVED_FILTER>;
type RCV_REMOVE_SAVED_FILTER_TYPE = BaseAction<typeof RCV_REMOVE_SAVED_FILTER> & {
  id: string;
};
type ERR_REMOVE_SAVED_FILTER_TYPE = BaseAction<typeof ERR_REMOVE_SAVED_FILTER>;

type ProfileAction =
  | REQ_GET_GRAPH_REPORT_TYPE
  | RCV_GET_GRAPH_REPORT_TYPE
  | ERR_GET_GRAPH_REPORT_TYPE
  | REQ_GET_GRAPH_REPORT_COMPARE_TYPE
  | RCV_GET_GRAPH_REPORT_COMPARE_TYPE
  | ERR_GET_GRAPH_REPORT_COMPARE_TYPE
  | REQ_GET_SAVED_FILTERS_TYPE
  | RCV_GET_SAVED_FILTERS_TYPE
  | ERR_GET_SAVED_FILTERS_TYPE
  | REQ_CREATE_SAVED_FILTERS_TYPE
  | RCV_CREATE_SAVED_FILTERS_TYPE
  | ERR_CREATE_SAVED_FILTERS_TYPE
  | REQ_REMOVE_SAVED_FILTER_TYPE
  | RCV_REMOVE_SAVED_FILTER_TYPE
  | ERR_REMOVE_SAVED_FILTER_TYPE;

interface ProfileState {
  graphReport?: GraphReport;
  graphReportCompare?: GraphReport;
  savedFilters?: SavedGraphFilter[];
  requestGetGraphReport: RequestState;
  requestGetGraphReportCompare: RequestState;
  requestGetSavedFilters: RequestState;
  requestCreateSavedFilters: RequestState;
  requestRemoveSavedFilter: RequestState;
}

export const graphReducerInitialState: ProfileState = {
  requestGetGraphReport: requestIdle(),
  requestGetGraphReportCompare: requestIdle(),
  requestGetSavedFilters: requestIdle(),
  requestCreateSavedFilters: requestIdle(),
  requestRemoveSavedFilter: requestIdle(),
};

const graphReducer = (state: ProfileState, action: ProfileAction) => {
  const next = cloneDeep(state);
  switch (action.type) {
    case REQ_GET_GRAPH_REPORT:
      next.requestGetGraphReport = requestInit();
      next.graphReport = undefined;
      next.graphReportCompare = undefined;
      return next;
    case RCV_GET_GRAPH_REPORT:
      next.requestGetGraphReport = requestSuccessful();
      next.graphReport = action.graphReport;
      return next;
    case ERR_GET_GRAPH_REPORT:
      next.requestGetGraphReport = requestError();
      return next;

    case REQ_GET_GRAPH_REPORT_COMPARE:
      next.requestGetGraphReportCompare = requestInit();
      return next;
    case RCV_GET_GRAPH_REPORT_COMPARE:
      next.requestGetGraphReportCompare = requestSuccessful();
      next.graphReportCompare = action.graphReport;
      return next;
    case ERR_GET_GRAPH_REPORT_COMPARE:
      next.requestGetGraphReportCompare = requestError();
      return next;

    case REQ_GET_SAVED_FILTERS:
      next.requestGetSavedFilters = requestInit();
      return next;
    case RCV_GET_SAVED_FILTERS:
      next.requestGetSavedFilters = requestSuccessful();
      next.savedFilters = action.savedFilters;
      return next;
    case ERR_GET_SAVED_FILTERS:
      next.requestGetSavedFilters = requestError();
      return next;

    case REQ_CREATE_SAVED_FILTERS:
      next.requestGetSavedFilters = requestInit();
      return next;
    case RCV_CREATE_SAVED_FILTERS:
      next.requestGetSavedFilters = requestSuccessful();
      next.savedFilters = [...(next.savedFilters || []), action.savedFilter];
      return next;
    case ERR_CREATE_SAVED_FILTERS:
      next.requestGetSavedFilters = requestError();
      return next;

    case REQ_REMOVE_SAVED_FILTER:
      next.requestRemoveSavedFilter = requestInit();
      return next;
    case RCV_REMOVE_SAVED_FILTER:
      next.requestRemoveSavedFilter = requestSuccessful();

      const index = next.savedFilters?.findIndex((x) => x.id === action.id);
      if (index !== undefined && index > -1 && next.savedFilters) {
        next.savedFilters.splice(index, 1);
      }
      return next;
    case ERR_REMOVE_SAVED_FILTER:
      next.requestRemoveSavedFilter = requestError();
      return next;

    default:
      throw new Error(`Unsupported action type for graphReducer`);
  }
};

export default graphReducer;
