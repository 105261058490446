import React, { useState } from 'react';

import Modal from '@components/Modal';
import { Text } from '@components/Text';
import { IconExternalLink } from '@components/Icon';
import { StackSmall } from '@components/Stack';
import { Button } from '@components/Button';
import { RouterLink } from '@components/Link';

import { ButtonWrapper } from './wrapper';
import Loader from '@components/Loader';
import { useFetcher } from '@services/useFetcher';
import { useTranslation } from 'react-i18next';

export const TermsAgreementModal = () => {
  const { fetchWithToken } = useFetcher();
  const { t } = useTranslation();

  const [modal, setModal] = useState(true);
  const [isLoading, setIsLoading] = useState(false);

  const handleClick = () => {
    setIsLoading(true);

    const url = `${process.env.CUSTOMER_PORTAL_API || ''}/profile/terms`;
    fetchWithToken(url, { method: 'PUT', body: { version: '1.0' } }).then(() => {
      setModal(false);

      // Remove old token to force refetch
      sessionStorage.removeItem('kc_token');
      // location.reload();
    });
  };

  return modal ? (
    <Modal title={t('terms_agreement_modal_title')} overlay>
      <StackSmall>
        <Text>{t('terms_agreement_modal_text')}</Text>
      </StackSmall>

      <StackSmall>
        <RouterLink
          title={t('terms_agreement_modal_heading')}
          target="_blank"
          rel="noopener noreferrer"
          to="/terms-of-use"
        >
          {t('terms_agreement_modal_read_more')} <IconExternalLink display="inline-block" />
        </RouterLink>
      </StackSmall>

      <ButtonWrapper>
        <Button onClick={() => handleClick()} disabled={isLoading}>
          {t('terms_agreement_modal_button_text')}
          {isLoading && <Loader style={{ marginLeft: '10px' }} inlineBlock />}
        </Button>
      </ButtonWrapper>
    </Modal>
  ) : null;
};
