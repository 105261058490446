import React from 'react';
import { ReactKeycloakProvider } from '@react-keycloak/web';
import { HashRouter } from 'react-router-dom';

import keycloak from './keycloak';
import AuthProvider from './State/Auth/AuthProvider';
import ProfileProvider from './State/Profile/ProfileProvider';
import ReportsProvider from './State/Reports/ReportsProvider';
import GraphProvider from './State/Graph/GraphProvider';
import UsersProvider from './State/Users/UsersProvider';
import SnackbarProvider from './State/Snackbar/SnackbarProvider';
import ImpersonateProvider from './State/Impersonate/ImpersonateProvider';
import PermissionsProvider from './State/Permissions/PermissionsProvider';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';

const token = sessionStorage.getItem('kc_token') || undefined;
const refreshToken = sessionStorage.getItem('kc_refreshToken') || undefined;

interface ProviderProps {
  children?: React.ReactNode;
}

interface InitialOptions {
  useNonce?: boolean;
  onLoad?: 'login-required' | 'check-sso';
  silentCheckSsoRedirectUri?: string;
  silentCheckSsoFallback?: boolean;
  token?: string;
  refreshToken?: string;
  idToken?: string;
  timeSkew?: number;
  checkLoginIframe?: boolean;
  checkLoginIframeInterval?: number;
  responseMode?: 'query' | 'fragment';
  flow?: 'standard' | 'implicit' | 'hybrid';
  enableLogging?: boolean;
  pkceMethod?: 'S256';
}

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
      retry: false,
    },
  },
});

const Providers = ({ children }: ProviderProps) => (
  <HashRouter>
    <QueryClientProvider client={queryClient}>
      <ReactKeycloakProvider
        authClient={keycloak}
        initOptions={
          {
            onLoad: 'check-sso',
            token,
            refreshToken,
            pkceMethod: 'S256',
            timeSkew: 0,
          } as InitialOptions
        }
      >
        <SnackbarProvider>
          <AuthProvider>
            <PermissionsProvider>
              <ImpersonateProvider>
                <GraphProvider>
                  <ProfileProvider>
                    <ReportsProvider>
                      <UsersProvider>{children}</UsersProvider>
                    </ReportsProvider>
                  </ProfileProvider>
                </GraphProvider>
              </ImpersonateProvider>
            </PermissionsProvider>
          </AuthProvider>
        </SnackbarProvider>
      </ReactKeycloakProvider>
    </QueryClientProvider>
  </HashRouter>
);

export default Providers;
