import styled from 'styled-components';

import { size, rgba } from '../../layout/helpers';
import { ButtonReset } from '../../layout/reset';
import { fadeIn, noTransform } from '../../layout/keyframes';
import { IModal } from '.';

const animationDuration = 200;

const closeBtnSize = (props: any) => props.theme.font.size.large;
const headingFontSize = (props: any) => props.theme.font.size.xxlarge;
const headingLineHeight = (props: any) => props.theme.font.lineHeight;

export const Wrapper = styled.div.attrs(() => ({
  role: 'dialog',
}))<IModal>`
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: ${size(2)};
  white-space: normal;
  text-align: left;
  z-index: 90;

  ${({ overlay }) =>
    overlay &&
    `
    background: rgba(0, 0, 0, 0.6);
  `}
`;

export const UiBlock = styled.div.attrs(() => ({
  'aria-hidden': 'true',
  role: 'button',
}))`
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: ${(props) => props.theme.color.overlay.main};
  animation: ${fadeIn} ${animationDuration}ms ease;
  z-index: -1;

  @media (prefers-reduced-motion) {
    animation: none;
  }
`;

export const Dialog = styled.article`
  display: flex;
  flex-direction: column;
  background-color: ${(props) => props.theme.color.bg.alt};
  z-index: 1;
  width: 100%;
  max-width: 780px;
  max-height: 100%;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.05);
  overflow: hidden;
  transform: translateY(${size(2)});
  animation: ${fadeIn} ${animationDuration}ms ease,
    ${noTransform} ${animationDuration}ms ease forwards;
`;

export const Content = styled.div`
  flex: 1 1 auto;
  overflow: auto;
  -webkit-overflow-scrolling: touch;
`;

export const Header = styled.header`
  position: relative;
  display: flex;
  align-items: flex-start;
  position: sticky;
  top: 0;
  padding: ${size(5)};
  padding-bottom: 0;
  background-color: ${(props) => props.theme.color.bg.alt};
  z-index: 10;

  &:after {
    content: '';
    position: absolute;
    top: 100%;
    left: 0;
    right: 0;
    height: ${size(3)};
    background-image: linear-gradient(
      ${(props) => props.theme.color.bg.alt},
      ${(props) => rgba(props.theme.color.bg.alt, 0)}
    );
  }

  ${(props) => props.theme.breakpoint.LtSm} {
    padding: ${size(3)};
    padding-bottom: 0;
  }
`;

export const Heading = styled.div`
  flex: 1 1 auto;
  font-size: ${headingFontSize};
  line-height: ${headingLineHeight};
`;

export const Close = styled.div`
  flex-shrink: 0;
  margin-top: calc((${closeBtnSize} - ${headingFontSize} * ${headingLineHeight}) / -2);
  padding-left: ${size(3)};
`;

export const CloseBtn = styled(ButtonReset)`
  position: relative;
  display: block;
  font-size: ${closeBtnSize};
  color: ${(props) => props.theme.color.text.main};
  transform-origin: 50% 50%;
  transition: transform 200ms ease;
  z-index: 2;

  &:before {
    content: '';
    position: absolute;
    top: 50%;
    left: 50%;
    width: ${size(5)};
    height: ${size(5)};
    border-radius: 50%;
    background-color: ${(props) => props.theme.color.bg.main};
    opacity: 0;
    transform: translate(-50%, -50%);
    transition: opacity 200ms ease;
    z-index: -1;
  }

  :active {
    transform: scale(0.9);
  }

  &:hover {
    &:before {
      opacity: 1;
    }
  }
`;

export const Body = styled.div`
  padding: ${size(5)};

  ${(props) => props.theme.breakpoint.LtSm} {
    padding: ${size(3)};
  }
`;
