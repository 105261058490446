import { createContext } from 'react';

import { RequestState } from '../../http/requestState';
import { SavedFilter, Spreadsheet, SpreadsheetGetRequest, ReportType } from './ReportsModels';

export interface ReportsContextProps {
  savedFilters?: SavedFilter[];
  spreadsheet?: Spreadsheet;
  requestGetSpreadsheet: RequestState;
  requestGetSavedFilters: RequestState;
  requestCreateSavedFilters: RequestState;
  requestRemoveSavedFilter: RequestState;
  getSpreadsheet: (report: ReportType, settings: SpreadsheetGetRequest) => void;
  getSavedFilters: () => void;
  createSavedFilter: (newSavedFilter: SavedFilter) => void;
  removeSavedFilter: (id: string) => void;
}

export const ReportsContext = createContext<ReportsContextProps | undefined>(undefined);
