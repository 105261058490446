import cloneDeep from 'lodash.clonedeep';
import { BaseAction } from '../../@types/Action';
import {
  RequestState,
  requestInit,
  requestSuccessful,
  requestError,
  requestIdle,
} from '../../http/requestState';
import { Profile } from './ProfileModels';

export const REQ_GET_PROFILE = 'REQ_GET_PROFILE';
export const RCV_GET_PROFILE = 'RCV_GET_PROFILE';
export const ERR_GET_PROFILE = 'ERR_GET_PROFILE';

type REQ_GET_PROFILE_TYPE = BaseAction<typeof REQ_GET_PROFILE>;
type RCV_GET_PROFILE_TYPE = BaseAction<typeof RCV_GET_PROFILE> & {
  profile: Profile;
};
type ERR_GET_PROFILE_TYPE = BaseAction<typeof ERR_GET_PROFILE>;

type ProfileAction = REQ_GET_PROFILE_TYPE | RCV_GET_PROFILE_TYPE | ERR_GET_PROFILE_TYPE;

interface ProfileState {
  profile?: Profile;
  requestGet: RequestState;
}

export const profileReducerInitialState: ProfileState = {
  requestGet: requestIdle(),
};

const profileReducer = (state: ProfileState, action: ProfileAction) => {
  const next = cloneDeep(state);
  switch (action.type) {
    case REQ_GET_PROFILE:
      next.requestGet = requestInit();
      return next;
    case RCV_GET_PROFILE:
      next.requestGet = requestSuccessful();
      next.profile = action.profile;
      return next;
    case ERR_GET_PROFILE:
      next.requestGet = requestError();
      return next;

    default:
      throw new Error(`Unsupported action type for profileReducer`);
  }
};

export default profileReducer;
