import React, { useMemo, useCallback, useReducer, useRef } from 'react';

import {
  buildAuthorizedDeleteRequest,
  buildAuthorizedGetRequest,
  buildAuthorizedPutRequest,
} from '../../http/request-templates';
import { ImpersonateContext } from './ImpersonateContext';
import impersonateReducer, {
  RCV_GET_IMPERSONATE,
  REQ_GET_IMPERSONATE,
  ERR_GET_IMPERSONATE,
  REQ_SET_IMPERSONATE,
  RCV_SET_IMPERSONATE,
  ERR_SET_IMPERSONATE,
  REQ_REMOVE_IMPERSONATE,
  RCV_REMOVE_IMPERSONATE,
  ERR_REMOVE_IMPERSONATE,
  impersonateReducerInitialState,
} from './impersonateReducer';
import useAuth from '../Auth/useAuth';
import usePermissions from '../Permissions/usePermissions';
import { Impersonate } from './ImpersonateModels';
import useHttp from '../../http/useHttp';

interface ImpersonateProviderProps {
  children: React.ReactNode;
}

const ImpersonateProvider = ({ children }: ImpersonateProviderProps) => {
  const { token } = useAuth();
  const { getPermissions } = usePermissions();
  const { httpRawJson } = useHttp();
  const tokenRef = useRef(token);
  tokenRef.current = token;

  const [impersonateState, dispatch] = useReducer(
    impersonateReducer,
    impersonateReducerInitialState
  );

  const getImpersonate = useCallback(async () => {
    if (!tokenRef.current) {
      throw new Error('Missing token');
    }

    try {
      dispatch({ type: REQ_GET_IMPERSONATE });
      const response = await httpRawJson<Impersonate>(
        `${process.env.CUSTOMER_PORTAL_API}/admin/impersonate`,
        buildAuthorizedGetRequest(tokenRef.current)
      );
      dispatch({ type: RCV_GET_IMPERSONATE, impersonate: response });
    } catch (e) {
      dispatch({ type: ERR_GET_IMPERSONATE });
    }
  }, []);

  const setImpersonate = useCallback(async (email: string) => {
    if (!tokenRef.current) {
      throw new Error('Missing token');
    }

    try {
      dispatch({ type: REQ_SET_IMPERSONATE, email });
      const response = await httpRawJson<Impersonate>(
        `${process.env.CUSTOMER_PORTAL_API}/admin/impersonate`,
        buildAuthorizedPutRequest(tokenRef.current, { email })
      );
      getPermissions();
      dispatch({ type: RCV_SET_IMPERSONATE, impersonate: response });
    } catch (e) {
      dispatch({ type: ERR_SET_IMPERSONATE });
    }
  }, []);

  const removeImpersonate = useCallback(async () => {
    if (!tokenRef.current) {
      throw new Error('Missing token');
    }

    try {
      dispatch({ type: REQ_REMOVE_IMPERSONATE });
      await httpRawJson(
        `${process.env.CUSTOMER_PORTAL_API}/admin/impersonate`,
        buildAuthorizedDeleteRequest(tokenRef.current)
      );
      getPermissions();
      dispatch({ type: RCV_REMOVE_IMPERSONATE });
    } catch (e) {
      dispatch({ type: ERR_REMOVE_IMPERSONATE });
    }
  }, []);

  const value = useMemo(
    () => ({
      ...impersonateState,
      getImpersonate,
      setImpersonate,
      removeImpersonate,
    }),
    [impersonateState]
  );

  return <ImpersonateContext.Provider value={value}>{children}</ImpersonateContext.Provider>;
};

export default ImpersonateProvider;
