import React from 'react';

import { Wrapper, Bar, LeftContent, RightContent } from './wrapper';
import { Container } from '../Container';

interface IStateBar {
  children: React.ReactNode;
  rightContent?: React.ReactNode;
}

const StateBar = ({ children, rightContent }: IStateBar) => (
  <Wrapper>
    <Container>
      <Bar>
        <LeftContent>{children}</LeftContent>
        {rightContent && <RightContent>{rightContent}</RightContent>}
      </Bar>
    </Container>
  </Wrapper>
);

export default StateBar;
