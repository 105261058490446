import * as http from './index';

export const buildGetRequest = (contentType?: string): RequestInit =>
  http.builder().method('GET').content(null, contentType).build();

export const buildPostRequest = (content?: any, contentType?: string | null): RequestInit =>
  http.builder().method('POST').content(content, contentType).build();

export const buildAuthorizedGetRequest = (
  token: string,
  contentType?: string,
  accept?: string
): RequestInit =>
  http.builder().method('GET').content(null, contentType).accept(accept).token(token).build();

export const buildAuthorizedPostRequest = (
  token: string,
  content?: any,
  contentType?: string | null
): RequestInit => http.builder().method('POST').content(content, contentType).token(token).build();

export const buildAuthorizedPutRequest = (token: string, content?: any): RequestInit =>
  http.builder().method('PUT').content(content).token(token).build();

export const buildAuthorizedDeleteRequest = (token: string): RequestInit =>
  http.builder().method('DELETE').token(token).build();
