import styled, { css } from 'styled-components';

export const ButtonReset = styled.button`
  display: inline-block;
  padding: 0;
  margin: 0;
  background-color: transparent;
  border: 0;
  border-radius: 0;
  font-family: ${(props) => props.theme.font.family};
  font-weight: ${(props) => props.theme.font.weight.regular};
  font-size: 1em;
  color: currentColor;
  line-height: 1.2;
  text-align: center;
  text-decoration: none;
  -webkit-tap-highlight-color: rgba(255, 255, 255, 0);
  cursor: pointer;
`;

const inputPlaceholderStyle = css`
  font-family: ${(props) => props.theme.font.family};
  font-weight: ${(props) => props.theme.font.weight.regular};
  color: ${(props) => props.theme.color.text.alt};
`;

export const inputPlaceholder = css`
  &::-webkit-input-placeholder {
    ${inputPlaceholderStyle}
  }

  &:-moz-placeholder {
    ${inputPlaceholderStyle}
  }

  &::-moz-placeholder {
    ${inputPlaceholderStyle}
  }

  &:-ms-input-placeholder {
    ${inputPlaceholderStyle}
  }
`;
