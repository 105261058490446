import React, { useMemo, useCallback, useReducer, useRef } from 'react';

import * as http from '../../http';
import { buildAuthorizedGetRequest } from '../../http/request-templates';
import { PermissionsContext } from './PermissionsContext';

import permissionsReducer, {
  ERR_GET_PERMISSIONS,
  permissionsReducerInitialState,
  RCV_GET_PERMISSIONS,
  REQ_GET_PERMISSIONS,
} from './permissionsReducer';
import useAuth from '../Auth/useAuth';

interface ProfileProviderProps {
  children: React.ReactNode;
}

const permissionsBase: string[] = [
  'GraphMaterialsByMonth',
  'GraphWasteHierarchyByMonth',
  'SpreadsheetHazardousWaste',
  'SpreadsheetStatistics',
];

const permissionsEstates: string[] = [
  'GraphEstatesMaterialsByMonth',
  'SpreadsheetEstates',
  'SpreadsheetEstatesStatistics',
];

const PermissionsProvider = ({ children }: ProfileProviderProps) => {
  const { token } = useAuth();
  const tokenRef = useRef(token);
  tokenRef.current = token;

  const [permissionsState, dispatch] = useReducer(
    permissionsReducer,
    permissionsReducerInitialState
  );

  const getPermissions = useCallback(async () => {
    if (!tokenRef.current) {
      throw new Error('Missing token');
    }

    try {
      dispatch({ type: REQ_GET_PERMISSIONS });
      const permissions = [...permissionsBase];
      const hasEstatesPermissions = await http.rawJson<{ estatesReport: boolean }>(
        `${process.env.CUSTOMER_PORTAL_API}/estates/permissions`,
        buildAuthorizedGetRequest(tokenRef.current)
      );

      if (hasEstatesPermissions?.estatesReport) {
        permissions.push(...permissionsEstates);
      }

      dispatch({ type: RCV_GET_PERMISSIONS, permissions });
    } catch (e) {
      dispatch({ type: ERR_GET_PERMISSIONS });
    }
  }, []);

  const value = useMemo(
    () => ({
      ...permissionsState,
      getPermissions,
    }),
    [permissionsState]
  );

  return <PermissionsContext.Provider value={value}>{children}</PermissionsContext.Provider>;
};

export default PermissionsProvider;
