import { useContext } from 'react';
import { AuthContext, AuthContextProps } from './AuthContext';

const useAuth = (): AuthContextProps => {
  const context = useContext(AuthContext);

  // Bypass check in Storybook
  if (typeof process !== 'undefined' && process?.env?.IS_STORYBOOK) {
    return {} as AuthContextProps;
  }

  if (!context) {
    throw new Error('useAuth must be used within a AuthProvider');
  }

  return context;
};

export default useAuth;
