import { useCallback } from 'react';
import { snackbarCreator, SnackBarOptions } from '../State/Snackbar/SnackbarProvider';
import useSnackbar from '../State/Snackbar/useSnackbar';
import { /*raw, json, ApiResponse,*/ rawJson } from './';

export interface OtherOptions {
  cache?: boolean;
  snackbarOptions?: SnackBarOptions;
}

const useHttp = () => {
  const { pushSnackbar } = useSnackbar();

  /*
  const httpRaw = useCallback(
    (url: string, options: RequestInit, snackbarOptions?: SnackBarOptions) =>
      raw(url, options, snackbarCreator(snackbarOptions, pushSnackbar)),
    []
  );

  const httpJson = useCallback(
    <T>(url: string, options: RequestInit, snackbarOptions?: SnackBarOptions) =>
      json<T>(url, options, snackbarCreator(snackbarOptions, pushSnackbar)),
    []
  );

  const httpApiJson = useCallback(
    <T>(url: string, options: RequestInit, snackbarOptions?: SnackBarOptions) =>
      json<ApiResponse<T>>(url, options, snackbarCreator(snackbarOptions, pushSnackbar)),
    []
  );
  */

  const httpRawJson = useCallback(
    <T>(url: string, options: RequestInit, otherOptions?: OtherOptions) =>
      rawJson<T>(url, options, {
        cache: otherOptions?.cache,
        snackbarHttp: otherOptions?.snackbarOptions
          ? snackbarCreator(otherOptions?.snackbarOptions, pushSnackbar)
          : undefined,
      }),
    []
  );

  return { /*httpRaw, httpJson, httpApiJson,*/ httpRawJson };
};

export default useHttp;
