import styled from 'styled-components';

import { size } from '../../layout/helpers';

export const ButtonWrapper = styled.div`
  display: flex;
  justify-content: flex-end;

  padding-top: ${size(5)};
`;
