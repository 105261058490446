import styled from 'styled-components';

export const Divider = styled.hr`
  border-top: ${({ theme }) => theme.color.bg.medium};
  margin: 32px 0;
`;

export const DividerThin = styled.hr`
  border-top: ${({ theme }) => theme.color.bg.medium};
  margin: 16px 0;
`;
