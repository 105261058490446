import React, { useContext } from 'react';
import { SubNavLink } from './wrapper';
import { HeaderCtx } from './HeaderContext';
import { IconBox } from '../Icon';
import { NavigationItem } from './NavigationListItem';

export interface SubNavItemProps extends NavigationItem {
  widget?: JSX.Element;
  icon?: JSX.Element;
  pathname?: string;
  onClick?: (e: React.MouseEvent<HTMLAnchorElement>) => void;
}

const SubNavItem = ({
  pathname,
  widget,
  icon,
  path,
  title,
  description,
  exact,
  onClick,
}: SubNavItemProps) => {
  const { isSelected } = useContext(HeaderCtx);

  if (!path) return null;

  return (
    <SubNavLink
      to={path}
      title={title}
      $isActive={pathname && !!isSelected(pathname, path, exact) ? true : false}
      onClick={onClick}
    >
      <aside>
        {icon && !widget && <IconBox>{icon}</IconBox>}
        {widget && widget}
      </aside>
      <div className="description">
        <span className="title">{title}</span>
        <span>{description}</span>
      </div>
    </SubNavLink>
  );
};

export default SubNavItem;
