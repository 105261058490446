import { createContext } from 'react';
import { ParsedToken } from './AuthModel';

export interface AuthContextProps {
  token?: string;
  parsedToken?: ParsedToken;
  authenticated: boolean;
  signOut: () => void;
}

export const AuthContext = createContext<AuthContextProps | undefined>(undefined);
