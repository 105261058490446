import React, { useContext } from 'react';

import { useTranslation } from 'react-i18next';
import { LinkButton, LinkButtonContent } from '../LinkButton';
import { Columns, Column } from '../Columns';
import { Skeleton } from '../Skeleton';
import { StackMedium, StackSmall, StackLarge } from '../Stack';
import styled from 'styled-components';
import useProfile from '@services/profile';
import { Text } from '../Text';
import Avatar from '@components/Avatar';
import { DividerThin } from '@components/Divider';
import { NavLink, useLocation } from 'react-router-dom';
import { UiBlock } from '@components/Modal/wrapper';
import { HeaderCtx } from './HeaderContext';

interface HeaderProfilePanelProps {
  onClose: () => void;
  onSignOut: () => void;
}

const Dropdown = styled.div`
  position: absolute;
  background: white;
  right: 0;
  top: 50px;
  width: 380px;
  max-width: 80vw;
  padding: 24px;
  z-index: 10;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.05);
`;

const NavList = styled.ul`
  list-style: none;
  padding-left: 0;

  li {
    padding-top: 10px;
    a,
    span {
      color: #393a42;
      text-decoration: none;
      &.active {
        color: var(--color-red, #e2001a);
      }
      &:hover,
      &:focus {
        color: var(--color-red, #e2001a);
        text-decoration: underline;
      }
    }
  }
`;
const HeaderProfileMenu = ({ onClose, onSignOut }: HeaderProfilePanelProps) => {
  const { t } = useTranslation();
  const { data: profile } = useProfile();
  const { pathname } = useLocation();

  const userInitials = profile && `${profile?.firstname?.charAt(0)}${profile?.lastname?.charAt(0)}`;
  const { isSelected } = useContext(HeaderCtx);

  return (
    <>
      <Dropdown title={t('header_profile_panel_title')}>
        <StackLarge>
          <StackMedium>
            <Columns $spacing={2} $valign="center">
              {profile ? (
                <>
                  <Column $noShrink>
                    <Avatar initials={userInitials} size="large" />
                  </Column>
                  <Column>
                    <Text $size="large">
                      {profile?.firstname} {profile?.lastname}
                    </Text>
                    <Text $tone="alt" $size="small">
                      <span style={{ wordBreak: 'break-word' }}>{profile?.email}</span>
                    </Text>
                  </Column>
                </>
              ) : (
                <>
                  <Column $noShrink>
                    <Skeleton $variant="circle" $width="56px" $height="56px" />
                  </Column>
                  <Column>
                    <Skeleton $variant="text" $width="140px" $height="1.5em" />
                    <Skeleton $variant="text" $width="165px" $height="1.25em" />
                  </Column>
                </>
              )}
            </Columns>
            <DividerThin />
            <StackSmall>
              <NavList>
                <li>
                  <NavLink
                    to="/admin/my-pages"
                    onClick={onClose}
                    className={isSelected(pathname, 'my-pages') ? 'active' : ''}
                  >
                    {t('my_pages')}
                  </NavLink>
                </li>
                <li>
                  <LinkButton
                    onClick={onSignOut}
                    title={t('header_profile_panel_signout_btn_title')}
                    $hasContent
                  >
                    <LinkButtonContent>
                      {t('header_profile_panel_signout_btn_text')}
                    </LinkButtonContent>
                  </LinkButton>
                </li>
              </NavList>
            </StackSmall>
          </StackMedium>
        </StackLarge>
      </Dropdown>
      <UiBlock title={t('panel_close_btn_title')} onClick={onClose} style={{ zIndex: 5 }} />
    </>
  );
};

export default HeaderProfileMenu;
