import styled, { css } from 'styled-components';

import { size } from '../../layout/helpers';
import { rotate } from '../../layout/keyframes';

export const Spinner = styled.div`
  position: relative;
  display: block;
  margin: 0 auto;
  width: ${size(4)};
  height: ${size(4)};
  border-radius: 50%;
  border: 4px solid currentColor;
  animation: ${rotate} 600ms linear infinite;

  @media (prefers-reduced-motion) {
    animation-duration: 1500ms;
  }
`;

const SpinnerIcon = styled.div`
  position: absolute;
  left: 50%;
  color: currentColor;
  font-size: ${(props) => props.theme.font.size.small};
`;

export const SpinnerIconTop = styled(SpinnerIcon)`
  top: -2px;
  transform: translate(-50%, -50%);
`;

export const SpinnerIconBottom = styled(SpinnerIcon)`
  bottom: -2px;
  transform: translate(-50%, 50%) rotate(180deg);
`;

interface WrapperProps {
  $inline?: boolean;
  $inlineBlock?: boolean;
}

export const Wrapper = styled.div.attrs(() => ({
  role: 'status',
}))<WrapperProps>`
  color: ${(props) => props.theme.color.brand.primary.main};

  ${({ $inline }) =>
    $inline &&
    css`
      display: $inline-block;
      vertical-align: middle;
      color: currentColor;

      ${Spinner} {
        width: 1em;
        height: 1em;
        border: 2px solid currentColor;
        border-top-color: transparent;
      }

      ${SpinnerIconTop},
      ${SpinnerIconBottom} {
        display: none;
      }
    `}

  ${({ $inlineBlock }) =>
    $inlineBlock &&
    css`
      color: currentColor;

      ${Spinner} {
        width: 1em;
        height: 1em;
        border: 2px solid currentColor;
        border-top-color: transparent;
      }

      ${SpinnerIconTop},
      ${SpinnerIconBottom} {
        display: none;
      }
    `}
`;
