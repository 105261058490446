import React, { useCallback, useRef } from 'react';
import useSnackbar from '../../State/Snackbar/useSnackbar';
import SnackbarItem from './SnackbarItem';

import { Wrapper } from './wrapper';

const Snackbar = () => {
  const { snackbars, removeSnackbar } = useSnackbar();
  const ref = useRef<HTMLDivElement | null>(null);

  const transform = useCallback((height: number) => {
    if (ref.current) {
      ref.current.style.transform = `translateY(-${height + 8}px)`;
      ref.current.style.transition = 'transform 300ms ease';
      setTimeout(() => {
        if (ref.current) {
          ref.current.style.transform = '';
          ref.current.style.transition = '';
        }
      }, 300);
    }
  }, []);

  return (
    <Wrapper ref={ref}>
      {snackbars.map((snackbar) => (
        <SnackbarItem
          key={snackbar.id}
          snackbar={snackbar}
          removeSnackbar={removeSnackbar}
          transform={transform}
        />
      ))}
    </Wrapper>
  );
};

export default Snackbar;
