import React from 'react';
import { Link as RRDLink } from 'react-router-dom';
import { Container } from '../Container';
import logotype from '../../assets/logotype/remondis-logo.svg';
import { Logo, LogoImg } from './wrapper';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import { size } from '../../layout/helpers';
import { Button } from '../Button';
import { useLoginUrl } from '../../hooks/useLoginUrl';
import { RouterLink } from '../Link';

const Wrapper = styled.div`
  background-color: ${(props) => props.theme.color.bg.alt};
`;

const Inner = styled.nav`
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: ${size(15)};

  ${(props) => props.theme.breakpoint.LtSm} {
    height: ${size(9)};
  }
`;

const ButtonContainer = styled.div`
  display: flex;

  ${(props) => props.theme.breakpoint.LtSm} {
    display: none;
  }
`;

export const PublicHeader = () => {
  const { t } = useTranslation();

  const loginUrl = useLoginUrl();

  return (
    <Wrapper>
      <Container>
        <Inner>
          <Logo>
            <RRDLink to="/" title={t('navigation_home_title')}>
              <LogoImg src={logotype} alt={t('header_logotype_alt')} />
            </RRDLink>
          </Logo>

          <ButtonContainer>
            <RouterLink to="/register-user">
              <Button $tone="clear-dark">{t('header_register_user_button')}</Button>
            </RouterLink>

            <Button $tone="outline" as="a" href={loginUrl || '/#'}>
              {t('header_login_button')}
            </Button>
          </ButtonContainer>
        </Inner>
      </Container>
    </Wrapper>
  );
};

export default PublicHeader;
