import { useLocation } from 'react-router-dom';
import { privateRoutes } from './private';

export const useRoutes = () => {
  const location = useLocation();

  const isPrivateRoute = privateRoutes.map((route) => route.path).includes(location.pathname);

  return {
    isPrivateRoute,
  };
};
