import styled from 'styled-components';

export const Container = styled.div`
  width: 100%;
  max-width: ${(props) => props.theme.size.containerMaxWidth};
  margin-left: auto;
  margin-right: auto;
  padding: 0 ${(props) => props.theme.size.containerGutter};
  padding-left: max(env(safe-area-inset-left), ${(props) => props.theme.size.containerGutter});
  padding-right: max(env(safe-area-inset-right), ${(props) => props.theme.size.containerGutter});

  ${(props) => props.theme.breakpoint.LtSm} {
    padding: 0 ${(props) => props.theme.size.containerGutterLtSm};
    padding-left: max(
      env(safe-area-inset-left),
      ${(props) => props.theme.size.containerGutterLtSm}
    );
    padding-right: max(
      env(safe-area-inset-right),
      ${(props) => props.theme.size.containerGutterLtSm}
    );
  }
`;
