import { RouteItem } from '../@types/routes';
import i18n from '../i18n';
import { lazy } from 'react';

const PrivacyPolicy = lazy(() => import('./PrivacyPolicy'));
const TermsOfUse = lazy(() => import('./TermsOfUse'));
const RegisterUser = lazy(() => import('./RegisterUser'));
const ScheduledWorkplace = lazy(() => import('./ScheduledWorkplace'));
const HazardousWasteOrder = lazy(() => import('./HazardousWasteOrder'));

export const publicRoutes: RouteItem[] = [
  {
    title: i18n.t('privacypolicy_title'),
    path: '/privacy-policy',
    component: PrivacyPolicy,
    exact: true,
  },
  {
    title: i18n.t('termsofuse_title'),
    path: '/terms-of-use',
    component: TermsOfUse,
    exact: true,
  },
  {
    title: i18n.t('register_user_title'),
    path: '/register-user',
    component: RegisterUser,
    exact: true,
  },
  {
    title: i18n.t('navigation_scheduled_title'),
    path: '/scheduled-workplace/:wp',
    component: ScheduledWorkplace,
    exact: true,
  },
  {
    title: 'Beställ tömning av farligt avfall',
    path: '/hazardous-waste/:container',
    component: HazardousWasteOrder,
    exact: true,
  },
];
