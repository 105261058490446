import cloneDeep from 'lodash.clonedeep';
import { BaseAction } from '../../@types/Action';
import {
  RequestState,
  requestInit,
  requestSuccessful,
  requestError,
  requestIdle,
} from '../../http/requestState';
import {
  ClientCreateUser,
  CreateUser,
  Payer,
  PayerUser,
  User,
  AdminSearchResult,
} from './UsersModels';

export const REQ_ADMIN_GET_PAYER = 'REQ_ADMIN_GET_PAYER';
export const RCV_ADMIN_GET_PAYER = 'RCV_ADMIN_GET_PAYER';
export const ERR_ADMIN_GET_PAYER = 'ERR_ADMIN_GET_PAYER';

type REQ_ADMIN_GET_PAYER_TYPE = BaseAction<typeof REQ_ADMIN_GET_PAYER>;
type RCV_ADMIN_GET_PAYER_TYPE = BaseAction<typeof RCV_ADMIN_GET_PAYER> & {
  payer: Payer;
};
type ERR_ADMIN_GET_PAYER_TYPE = BaseAction<typeof ERR_ADMIN_GET_PAYER>;

export const REQ_CLIENT_GET_USERS = 'REQ_CLIENT_GET_USERS';
export const RCV_CLIENT_GET_USERS = 'RCV_CLIENT_GET_USERS';
export const ERR_CLIENT_GET_USERS = 'ERR_CLIENT_GET_USERS';

type REQ_CLIENT_GET_USERS_TYPE = BaseAction<typeof REQ_CLIENT_GET_USERS>;
type RCV_CLIENT_GET_USERS_TYPE = BaseAction<typeof RCV_CLIENT_GET_USERS> & {
  users: PayerUser[];
};
type ERR_CLIENT_GET_USERS_TYPE = BaseAction<typeof ERR_CLIENT_GET_USERS>;

export const REQ_CLIENT_GET_USER = 'REQ_CLIENT_GET_USER';
export const RCV_CLIENT_GET_USER = 'RCV_CLIENT_GET_USER';
export const ERR_CLIENT_GET_USER = 'ERR_CLIENT_GET_USER';

type REQ_CLIENT_GET_USER_TYPE = BaseAction<typeof REQ_CLIENT_GET_USER>;
type RCV_CLIENT_GET_USER_TYPE = BaseAction<typeof RCV_CLIENT_GET_USER> & {
  user: User;
};
type ERR_CLIENT_GET_USER_TYPE = BaseAction<typeof ERR_CLIENT_GET_USER>;

export const REQ_ADMIN_GET_USER = 'REQ_ADMIN_GET_USER';
export const RCV_ADMIN_GET_USER = 'RCV_ADMIN_GET_USER';
export const ERR_ADMIN_GET_USER = 'ERR_ADMIN_GET_USER';

type REQ_ADMIN_GET_USER_TYPE = BaseAction<typeof REQ_ADMIN_GET_USER>;
type RCV_ADMIN_GET_USER_TYPE = BaseAction<typeof RCV_ADMIN_GET_USER> & {
  user: User;
};
type ERR_ADMIN_GET_USER_TYPE = BaseAction<typeof ERR_ADMIN_GET_USER>;

export const REQ_ADMIN_CREATE_CLIENT_ADMIN = 'REQ_ADMIN_CREATE_CLIENT_ADMIN';
export const RCV_ADMIN_CREATE_CLIENT_ADMIN = 'RCV_ADMIN_CREATE_CLIENT_ADMIN';
export const ERR_ADMIN_CREATE_CLIENT_ADMIN = 'ERR_ADMIN_CREATE_CLIENT_ADMIN';

type REQ_ADMIN_CREATE_CLIENT_ADMIN_TYPE = BaseAction<typeof REQ_ADMIN_CREATE_CLIENT_ADMIN>;
type RCV_ADMIN_CREATE_CLIENT_ADMIN_TYPE = BaseAction<typeof RCV_ADMIN_CREATE_CLIENT_ADMIN> & {
  user: CreateUser;
};
type ERR_ADMIN_CREATE_CLIENT_ADMIN_TYPE = BaseAction<typeof ERR_ADMIN_CREATE_CLIENT_ADMIN>;

export const REQ_CLIENT_CREATE_USER = 'REQ_CLIENT_CREATE_USER';
export const RCV_CLIENT_CREATE_USER = 'RCV_CLIENT_CREATE_USER';
export const ERR_CLIENT_CREATE_USER = 'ERR_CLIENT_CREATE_USER';

type REQ_CLIENT_CREATE_USER_TYPE = BaseAction<typeof REQ_CLIENT_CREATE_USER>;
type RCV_CLIENT_CREATE_USER_TYPE = BaseAction<typeof RCV_CLIENT_CREATE_USER> & {
  user: ClientCreateUser;
};
type ERR_CLIENT_CREATE_USER_TYPE = BaseAction<typeof ERR_CLIENT_CREATE_USER>;

export const REQ_ADMIN_UPDATE_CLIENT_ADMIN = 'REQ_ADMIN_UPDATE_CLIENT_ADMIN';
export const RCV_ADMIN_UPDATE_CLIENT_ADMIN = 'RCV_ADMIN_UPDATE_CLIENT_ADMIN';
export const ERR_ADMIN_UPDATE_CLIENT_ADMIN = 'ERR_ADMIN_UPDATE_CLIENT_ADMIN';

type REQ_ADMIN_UPDATE_CLIENT_ADMIN_TYPE = BaseAction<typeof REQ_ADMIN_UPDATE_CLIENT_ADMIN>;
type RCV_ADMIN_UPDATE_CLIENT_ADMIN_TYPE = BaseAction<typeof RCV_ADMIN_UPDATE_CLIENT_ADMIN> & {
  user: CreateUser;
};
type ERR_ADMIN_UPDATE_CLIENT_ADMIN_TYPE = BaseAction<typeof ERR_ADMIN_UPDATE_CLIENT_ADMIN>;

export const REQ_CLIENT_UPDATE_USER = 'REQ_CLIENT_UPDATE_USER';
export const RCV_CLIENT_UPDATE_USER = 'RCV_CLIENT_UPDATE_USER';
export const ERR_CLIENT_UPDATE_USER = 'ERR_CLIENT_UPDATE_USER';

type REQ_CLIENT_UPDATE_USER_TYPE = BaseAction<typeof REQ_CLIENT_UPDATE_USER>;
type RCV_CLIENT_UPDATE_USER_TYPE = BaseAction<typeof RCV_CLIENT_UPDATE_USER> & {
  // user: ClientCreateUser;
  user: User;
};
type ERR_CLIENT_UPDATE_USER_TYPE = BaseAction<typeof ERR_CLIENT_UPDATE_USER>;

export const REQ_ADMIN_REMOVE_USER = 'REQ_ADMIN_REMOVE_USER';
export const RCV_ADMIN_REMOVE_USER = 'RCV_ADMIN_REMOVE_USER';
export const ERR_ADMIN_REMOVE_USER = 'ERR_ADMIN_REMOVE_USER';

type REQ_ADMIN_REMOVE_USER_TYPE = BaseAction<typeof REQ_ADMIN_REMOVE_USER>;
type RCV_ADMIN_REMOVE_USER_TYPE = BaseAction<typeof RCV_ADMIN_REMOVE_USER> & {
  email: string;
};
type ERR_ADMIN_REMOVE_USER_TYPE = BaseAction<typeof ERR_ADMIN_REMOVE_USER>;

export const REQ_CLIENT_ENABLE_DISABLE = 'REQ_CLIENT_ENABLE_DISABLE';
export const RCV_CLIENT_ENABLE_DISABLE = 'RCV_CLIENT_ENABLE_DISABLE';
export const ERR_CLIENT_ENABLE_DISABLE = 'ERR_CLIENT_ENABLE_DISABLE';

type REQ_CLIENT_ENABLE_DISABLE_TYPE = BaseAction<typeof REQ_CLIENT_ENABLE_DISABLE>;
type RCV_CLIENT_ENABLE_DISABLE_TYPE = BaseAction<typeof RCV_CLIENT_ENABLE_DISABLE> & {
  email: string;
  enable: boolean;
};
type ERR_CLIENT_ENABLE_DISABLE_TYPE = BaseAction<typeof ERR_CLIENT_ENABLE_DISABLE>;

export const REQ_ADMIN_RESET_PASSWORD = 'REQ_ADMIN_RESET_PASSWORD';
export const RCV_ADMIN_RESET_PASSWORD = 'RCV_ADMIN_RESET_PASSWORD';
export const ERR_ADMIN_RESET_PASSWORD = 'ERR_ADMIN_RESET_PASSWORD';

type REQ_ADMIN_RESET_PASSWORD_TYPE = BaseAction<typeof REQ_ADMIN_RESET_PASSWORD>;
type RCV_ADMIN_RESET_PASSWORD_TYPE = BaseAction<typeof RCV_ADMIN_RESET_PASSWORD>;
type ERR_ADMIN_RESET_PASSWORD_TYPE = BaseAction<typeof ERR_ADMIN_RESET_PASSWORD>;

export const REQ_ADMIN_ENABLE_DISABLE = 'REQ_ADMIN_ENABLE_DISABLE';
export const RCV_ADMIN_ENABLE_DISABLE = 'RCV_ADMIN_ENABLE_DISABLE';
export const ERR_ADMIN_ENABLE_DISABLE = 'ERR_ADMIN_ENABLE_DISABLE';

type REQ_ADMIN_ENABLE_DISABLE_TYPE = BaseAction<typeof REQ_ADMIN_ENABLE_DISABLE>;
type RCV_ADMIN_ENABLE_DISABLE_TYPE = BaseAction<typeof RCV_ADMIN_ENABLE_DISABLE> & {
  email: string;
  enable: boolean;
};
type ERR_ADMIN_ENABLE_DISABLE_TYPE = BaseAction<typeof ERR_ADMIN_ENABLE_DISABLE>;

export const REQ_CLIENT_RESET_PASSWORD = 'REQ_CLIENT_RESET_PASSWORD';
export const RCV_CLIENT_RESET_PASSWORD = 'RCV_CLIENT_RESET_PASSWORD';
export const ERR_CLIENT_RESET_PASSWORD = 'ERR_CLIENT_RESET_PASSWORD';

type REQ_CLIENT_RESET_PASSWORD_TYPE = BaseAction<typeof REQ_CLIENT_RESET_PASSWORD>;
type RCV_CLIENT_RESET_PASSWORD_TYPE = BaseAction<typeof RCV_CLIENT_RESET_PASSWORD>;
type ERR_CLIENT_RESET_PASSWORD_TYPE = BaseAction<typeof ERR_CLIENT_RESET_PASSWORD>;

export const REQ_ADMIN_SEARCH = 'REQ_ADMIN_SEARCH';
export const RCV_ADMIN_SEARCH = 'RCV_ADMIN_SEARCH';
export const ERR_ADMIN_SEARCH = 'ERR_ADMIN_SEARCH';

type REQ_ADMIN_SEARCH_TYPE = BaseAction<typeof REQ_ADMIN_SEARCH>;
type RCV_ADMIN_SEARCH_TYPE = BaseAction<typeof RCV_ADMIN_SEARCH> & {
  adminSearchResult: AdminSearchResult[];
};
type ERR_ADMIN_SEARCH_TYPE = BaseAction<typeof ERR_ADMIN_SEARCH>;

export const CLEAR_USER = 'CLEAR_USER';
type CLEAR_USER_TYPE = BaseAction<typeof CLEAR_USER>;

type UsersAction =
  | REQ_ADMIN_GET_PAYER_TYPE
  | RCV_ADMIN_GET_PAYER_TYPE
  | ERR_ADMIN_GET_PAYER_TYPE
  | REQ_CLIENT_GET_USERS_TYPE
  | RCV_CLIENT_GET_USERS_TYPE
  | ERR_CLIENT_GET_USERS_TYPE
  | REQ_CLIENT_GET_USER_TYPE
  | RCV_CLIENT_GET_USER_TYPE
  | ERR_CLIENT_GET_USER_TYPE
  | REQ_ADMIN_GET_USER_TYPE
  | RCV_ADMIN_GET_USER_TYPE
  | ERR_ADMIN_GET_USER_TYPE
  | REQ_ADMIN_CREATE_CLIENT_ADMIN_TYPE
  | RCV_ADMIN_CREATE_CLIENT_ADMIN_TYPE
  | ERR_ADMIN_CREATE_CLIENT_ADMIN_TYPE
  | REQ_ADMIN_UPDATE_CLIENT_ADMIN_TYPE
  | RCV_ADMIN_UPDATE_CLIENT_ADMIN_TYPE
  | ERR_ADMIN_UPDATE_CLIENT_ADMIN_TYPE
  | REQ_CLIENT_UPDATE_USER_TYPE
  | RCV_CLIENT_UPDATE_USER_TYPE
  | ERR_CLIENT_UPDATE_USER_TYPE
  | REQ_CLIENT_CREATE_USER_TYPE
  | RCV_CLIENT_CREATE_USER_TYPE
  | ERR_CLIENT_CREATE_USER_TYPE
  | REQ_ADMIN_REMOVE_USER_TYPE
  | RCV_ADMIN_REMOVE_USER_TYPE
  | ERR_ADMIN_REMOVE_USER_TYPE
  | REQ_ADMIN_RESET_PASSWORD_TYPE
  | RCV_ADMIN_RESET_PASSWORD_TYPE
  | ERR_ADMIN_RESET_PASSWORD_TYPE
  | REQ_CLIENT_ENABLE_DISABLE_TYPE
  | RCV_CLIENT_ENABLE_DISABLE_TYPE
  | ERR_CLIENT_ENABLE_DISABLE_TYPE
  | REQ_CLIENT_RESET_PASSWORD_TYPE
  | RCV_CLIENT_RESET_PASSWORD_TYPE
  | ERR_CLIENT_RESET_PASSWORD_TYPE
  | REQ_ADMIN_ENABLE_DISABLE_TYPE
  | RCV_ADMIN_ENABLE_DISABLE_TYPE
  | ERR_ADMIN_ENABLE_DISABLE_TYPE
  | REQ_ADMIN_SEARCH_TYPE
  | RCV_ADMIN_SEARCH_TYPE
  | ERR_ADMIN_SEARCH_TYPE
  | CLEAR_USER_TYPE;

interface UsersState {
  payer?: Payer;
  user?: User;
  clientUsers?: PayerUser[];
  clientUser?: User;
  requestAdminGetPayer: RequestState;
  requestAdminGetUser: RequestState;
  requestAdminCreateUser: RequestState;
  requestAdminCreateClientAdmin: RequestState;
  requestAdminUpdateClientAdmin: RequestState;
  requestAdminRemoveUser: RequestState;
  requestAdminResetPassword: RequestState;
  requestAdminEnableDisable: RequestState;

  requestClientGetUsers: RequestState;
  requestClientGetUser: RequestState;
  requestClientCreateUser: RequestState;
  requestClientUpdateUser: RequestState;
  requestClientResetPassword: RequestState;
  requestClientEnableDisable: RequestState;

  requestAdminSearch: RequestState;
  adminSearchResult?: AdminSearchResult[];
}

export const usersReducerInitialState: UsersState = {
  requestAdminGetPayer: requestIdle(),
  requestAdminGetUser: requestIdle(),
  requestAdminCreateUser: requestIdle(),
  requestAdminCreateClientAdmin: requestIdle(),
  requestAdminUpdateClientAdmin: requestIdle(),
  requestAdminRemoveUser: requestIdle(),
  requestAdminResetPassword: requestIdle(),
  requestAdminEnableDisable: requestIdle(),

  requestClientGetUsers: requestIdle(),
  requestClientGetUser: requestIdle(),
  requestClientCreateUser: requestIdle(),
  requestClientUpdateUser: requestIdle(),
  requestClientResetPassword: requestIdle(),
  requestClientEnableDisable: requestIdle(),

  requestAdminSearch: requestIdle(),
};

let index: number | undefined;
const usersReducer = (state: UsersState, action: UsersAction) => {
  const next = cloneDeep(state);
  switch (action.type) {
    case REQ_ADMIN_GET_PAYER:
      next.payer = undefined;
      next.requestAdminGetPayer = requestInit();
      return next;
    case RCV_ADMIN_GET_PAYER:
      next.requestAdminGetPayer = requestSuccessful();
      next.payer = action.payer;
      return next;
    case ERR_ADMIN_GET_PAYER:
      next.requestAdminGetPayer = requestError();
      return next;

    case REQ_ADMIN_GET_USER:
      next.requestAdminGetUser = requestInit();
      return next;
    case RCV_ADMIN_GET_USER:
      next.requestAdminGetUser = requestSuccessful();
      next.user = action.user;
      return next;
    case ERR_ADMIN_GET_USER:
      next.requestAdminGetUser = requestError();
      return next;

    case REQ_ADMIN_CREATE_CLIENT_ADMIN:
      next.requestAdminCreateClientAdmin = requestInit();
      return next;
    case RCV_ADMIN_CREATE_CLIENT_ADMIN:
      next.requestAdminCreateClientAdmin = requestSuccessful();

      if (next.payer?.users) {
        next.payer.users.push({
          clientadmin: action.user.clientadmin,
          email: action.user.email,
          enabled: action.user.enabled,
          firstname: action.user.firstname,
          lastname: action.user.lastname,
          created: new Date().toISOString(),
        });
      }

      return next;
    case ERR_ADMIN_CREATE_CLIENT_ADMIN:
      next.requestAdminCreateClientAdmin = requestError();
      return next;

    case REQ_ADMIN_UPDATE_CLIENT_ADMIN:
      next.requestAdminUpdateClientAdmin = requestInit();
      return next;
    case RCV_ADMIN_UPDATE_CLIENT_ADMIN:
      next.requestAdminUpdateClientAdmin = requestSuccessful();
      if (next.user && next.user.email === action.user.email) {
        if (next.user.clientadmin) {
          next.user = { ...next.user, ...action.user };
        } else {
          next.user = { ...next.user, ...action.user, payers: next.user.payers };
        }
      }
      if (next.payer && next.user?.email) {
        index = next.payer.users.findIndex((x) => x.email === (next.user as User).email);
        if (index > -1) {
          if (next.payer.id !== next.user.payers?.[0]?.id) {
            next.payer.users.splice(index, 1);
          } else {
            next.payer.users[index] = {
              ...next.payer.users[index],
              clientadmin: next.user.clientadmin,
              firstname: next.user.firstname,
              lastname: next.user.lastname,
              enabled: next.user.enabled,
            };
          }
        }
      }

      return next;
    case ERR_ADMIN_UPDATE_CLIENT_ADMIN:
      next.requestAdminUpdateClientAdmin = requestError();
      return next;

    case REQ_ADMIN_REMOVE_USER:
      next.requestAdminRemoveUser = requestInit();
      return next;
    case RCV_ADMIN_REMOVE_USER:
      next.requestAdminRemoveUser = requestSuccessful();
      if (next.payer) {
        index = next.payer.users.findIndex((x) => x.email === action.email);
        if (index > -1) {
          next.payer.users.splice(index, 1);
        }
      }
      if (next.clientUsers) {
        index = next.clientUsers.findIndex((x) => x.email === action.email);
        if (index > -1) {
          next.clientUsers.splice(index, 1);
        }
      }
      return next;
    case ERR_ADMIN_REMOVE_USER:
      next.requestAdminRemoveUser = requestError();
      return next;

    case REQ_CLIENT_GET_USERS:
      next.payer = undefined;
      next.requestClientGetUsers = requestInit();
      return next;
    case RCV_CLIENT_GET_USERS:
      next.requestClientGetUsers = requestSuccessful();
      next.clientUsers = action.users;
      return next;
    case ERR_CLIENT_GET_USERS:
      next.requestClientGetUsers = requestError();
      return next;

    case REQ_CLIENT_GET_USER:
      next.user = undefined;
      next.requestClientGetUser = requestInit();
      return next;
    case RCV_CLIENT_GET_USER:
      next.requestClientGetUser = requestSuccessful();
      next.clientUser = action.user;
      return next;
    case ERR_CLIENT_GET_USER:
      next.requestClientGetUser = requestError();
      return next;

    case REQ_CLIENT_CREATE_USER:
      next.requestClientCreateUser = requestInit();
      return next;
    case RCV_CLIENT_CREATE_USER:
      next.requestClientCreateUser = requestSuccessful();
      if (next.clientUsers) {
        next.clientUsers = [
          ...(next.clientUsers || []),
          {
            clientadmin: action.user.clientadmin ?? false,
            created: new Date().toISOString(),
            email: action.user.email,
            enabled: action.user.enabled,
            firstname: action.user.firstname,
            lastname: action.user.lastname,
          },
        ];
      }

      return next;
    case ERR_CLIENT_CREATE_USER:
      next.requestClientCreateUser = requestError();
      return next;

    case REQ_CLIENT_UPDATE_USER:
      next.requestClientUpdateUser = requestInit();
      return next;
    case RCV_CLIENT_UPDATE_USER:
      next.requestClientUpdateUser = requestSuccessful();
      next.clientUser = action.user;
      if (next.clientUsers && next.clientUser?.email) {
        index = next.clientUsers.findIndex((x) => x.email === (next.clientUser as User).email);
        if (index > -1) {
          next.clientUsers[index] = {
            ...next.clientUsers[index],
            clientadmin: next.clientUser.clientadmin,
            firstname: next.clientUser.firstname,
            lastname: next.clientUser.lastname,
            enabled: next.clientUser.enabled,
          };
        }
      }

      return next;
    case ERR_CLIENT_UPDATE_USER:
      next.requestClientUpdateUser = requestError();
      return next;

    case REQ_ADMIN_RESET_PASSWORD:
      next.user = undefined;
      next.requestAdminResetPassword = requestInit();
      return next;
    case RCV_ADMIN_RESET_PASSWORD:
      next.requestAdminResetPassword = requestSuccessful();
      return next;
    case ERR_ADMIN_RESET_PASSWORD:
      next.requestAdminResetPassword = requestError();
      return next;

    case REQ_ADMIN_ENABLE_DISABLE:
      next.requestAdminEnableDisable = requestInit();
      return next;
    case RCV_ADMIN_ENABLE_DISABLE:
      next.requestAdminEnableDisable = requestSuccessful();
      index = next.payer?.users.findIndex((x) => x.email === action.email);
      if (index !== undefined && index > -1 && next.payer) {
        next.payer.users[index].enabled = action.enable;
      }
      return next;
    case ERR_ADMIN_ENABLE_DISABLE:
      next.requestAdminEnableDisable = requestError();
      return next;

    case REQ_CLIENT_RESET_PASSWORD:
      next.user = undefined;
      next.requestClientResetPassword = requestInit();
      return next;
    case RCV_CLIENT_RESET_PASSWORD:
      next.requestClientResetPassword = requestSuccessful();
      return next;
    case ERR_CLIENT_RESET_PASSWORD:
      next.requestClientResetPassword = requestError();
      return next;

    case REQ_CLIENT_ENABLE_DISABLE:
      next.user = undefined;
      next.requestClientEnableDisable = requestInit();
      return next;
    case RCV_CLIENT_ENABLE_DISABLE:
      next.requestClientEnableDisable = requestSuccessful();
      index = next.clientUsers?.findIndex((x) => x.email === action.email);
      if (index !== undefined && index > -1 && next.clientUsers) {
        next.clientUsers[index].enabled = action.enable;
      }
      return next;
    case ERR_CLIENT_ENABLE_DISABLE:
      next.requestClientEnableDisable = requestError();
      return next;

    case CLEAR_USER:
      next.user = undefined;
      return next;

    case REQ_ADMIN_SEARCH:
      next.requestAdminSearch = requestInit();
      next.adminSearchResult = undefined;
      return next;
    case RCV_ADMIN_SEARCH:
      next.requestAdminSearch = requestSuccessful();
      next.adminSearchResult = action.adminSearchResult;
      return next;
    case ERR_ADMIN_SEARCH:
      next.requestAdminSearch = requestError();
      return next;

    default:
      throw new Error(`Unsupported action type for usersReducer`);
  }
};

export default usersReducer;
