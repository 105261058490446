import { createContext } from 'react';

import { RequestState } from '../../http/requestState';

export interface PermissionsContextProps {
  permissions?: string[];
  requestGet: RequestState;
  getPermissions: () => void;
}

export const PermissionsContext = createContext<PermissionsContextProps | undefined>(undefined);
