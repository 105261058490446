import { useKeycloak } from '@react-keycloak/web';

interface useLoginUrlProps {
  returnUrl?: string;
}

const defaultProps = {
  returnUrl: `${window.location.origin}/#/`,
};

export const useLoginUrl = ({ returnUrl }: useLoginUrlProps = defaultProps) => {
  const { keycloak, initialized } = useKeycloak();

  if (!initialized) {
    return null;
  }

  keycloak.redirectUri = returnUrl;
  const loginUrl = keycloak.createLoginUrl();

  return loginUrl;
};
