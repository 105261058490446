import { createGlobalStyle } from 'styled-components';
import { Theme } from './theme';
import { utilityClasses } from './utilityClasses';

export default createGlobalStyle<{ theme: Theme }>`
  *,
  *:before,
  *:after {
    box-sizing: border-box;
  }

  html,
  body,
  #root {
    min-height: 100%;
  }

  /* always show scrollbar to avoid page jump */
  body {
    overflow-y: scroll;
  }

  html {
    font-size: 100%;
    -webkit-text-size-adjust: 100%;
    background-color: ${(props) => props.theme.color.brand.secondary.main};
  }

  body {
    margin: 0;
    background-color: ${(props) => props.theme.color.bg.main};
    color: ${(props) => props.theme.color.text.main};
    font-family: ${(props) => props.theme.font.family};
    line-height: ${(props) => props.theme.font.lineHeight};
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    letter-spacing: -0.2px;
  }

  a img {
    border: 0;
  }

  b,
  strong {
    font-weight: ${(props) => props.theme.font.weight.bold};
  }

  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    margin: 0;
  }

  pre {
    background: #d72669;
    background: springgreen;
    color: #fcfae1;
    color: #8e0191;
    padding: 0.5rem 1rem;
    border-radius: 4px;
    display: table;
  }

  pre.todo::before {
    content: "TODO: ";
    color: #d72669;
  }

  ${utilityClasses}

  .hide {
    opacity: 0;
  }
`;
