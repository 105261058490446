import cloneDeep from 'lodash.clonedeep';
import { BaseAction } from '../../@types/Action';
import {
  RequestState,
  requestInit,
  requestSuccessful,
  requestError,
  requestIdle,
} from '../../http/requestState';
import { Impersonate } from './ImpersonateModels';

export const REQ_GET_IMPERSONATE = 'REQ_GET_IMPERSONATE';
export const RCV_GET_IMPERSONATE = 'RCV_GET_IMPERSONATE';
export const ERR_GET_IMPERSONATE = 'ERR_GET_IMPERSONATE';

type REQ_GET_IMPERSONATE_TYPE = BaseAction<typeof REQ_GET_IMPERSONATE>;
type RCV_GET_IMPERSONATE_TYPE = BaseAction<typeof RCV_GET_IMPERSONATE> & {
  impersonate: Impersonate;
};
type ERR_GET_IMPERSONATE_TYPE = BaseAction<typeof ERR_GET_IMPERSONATE>;

export const REQ_SET_IMPERSONATE = 'REQ_SET_IMPERSONATE';
export const RCV_SET_IMPERSONATE = 'RCV_SET_IMPERSONATE';
export const ERR_SET_IMPERSONATE = 'ERR_SET_IMPERSONATE';

type REQ_SET_IMPERSONATE_TYPE = BaseAction<typeof REQ_SET_IMPERSONATE> & {
  email: string;
};
type RCV_SET_IMPERSONATE_TYPE = BaseAction<typeof RCV_SET_IMPERSONATE> & {
  impersonate: Impersonate;
};
type ERR_SET_IMPERSONATE_TYPE = BaseAction<typeof ERR_SET_IMPERSONATE>;

export const REQ_REMOVE_IMPERSONATE = 'REQ_REMOVE_IMPERSONATE';
export const RCV_REMOVE_IMPERSONATE = 'RCV_REMOVE_IMPERSONATE';
export const ERR_REMOVE_IMPERSONATE = 'ERR_REMOVE_IMPERSONATE';

type REQ_REMOVE_IMPERSONATE_TYPE = BaseAction<typeof REQ_REMOVE_IMPERSONATE>;
type RCV_REMOVE_IMPERSONATE_TYPE = BaseAction<typeof RCV_REMOVE_IMPERSONATE>;
type ERR_REMOVE_IMPERSONATE_TYPE = BaseAction<typeof ERR_REMOVE_IMPERSONATE>;

type ImpersonateAction =
  | REQ_GET_IMPERSONATE_TYPE
  | RCV_GET_IMPERSONATE_TYPE
  | ERR_GET_IMPERSONATE_TYPE
  | REQ_SET_IMPERSONATE_TYPE
  | RCV_SET_IMPERSONATE_TYPE
  | ERR_SET_IMPERSONATE_TYPE
  | REQ_REMOVE_IMPERSONATE_TYPE
  | RCV_REMOVE_IMPERSONATE_TYPE
  | ERR_REMOVE_IMPERSONATE_TYPE;

interface ImpersonateState {
  impersonate?: Impersonate;
  impersonating?: string;
  hasFetched?: boolean;
  requestGetImpersonate: RequestState;
  requestSetImpersonate: RequestState;
  requestRemoveImpersonate: RequestState;
}

export const impersonateReducerInitialState: ImpersonateState = {
  requestGetImpersonate: requestIdle(),
  requestSetImpersonate: requestIdle(),
  requestRemoveImpersonate: requestIdle(),
};

const impersonateReducer = (state: ImpersonateState, action: ImpersonateAction) => {
  const next = cloneDeep(state);
  switch (action.type) {
    case REQ_GET_IMPERSONATE:
      next.requestGetImpersonate = requestInit();
      return next;
    case RCV_GET_IMPERSONATE:
      next.requestGetImpersonate = requestSuccessful();
      next.impersonate = action.impersonate;
      next.hasFetched = true;
      return next;
    case ERR_GET_IMPERSONATE:
      next.requestGetImpersonate = requestError();
      next.hasFetched = true;
      return next;

    case REQ_SET_IMPERSONATE:
      next.requestSetImpersonate = requestInit();
      next.impersonating = action.email;
      return next;
    case RCV_SET_IMPERSONATE:
      next.requestSetImpersonate = requestSuccessful();
      next.impersonate = action.impersonate;
      next.impersonating = undefined;
      return next;
    case ERR_SET_IMPERSONATE:
      next.requestSetImpersonate = requestError();
      next.impersonating = undefined;
      return next;

    case REQ_REMOVE_IMPERSONATE:
      next.requestRemoveImpersonate = requestInit();
      return next;
    case RCV_REMOVE_IMPERSONATE:
      next.requestRemoveImpersonate = requestSuccessful();
      next.impersonate = undefined;
      return next;
    case ERR_REMOVE_IMPERSONATE:
      next.requestRemoveImpersonate = requestError();
      return next;

    default:
      throw new Error(`Unsupported action type for graphReducer`);
  }
};

export default impersonateReducer;
