import { createContext } from 'react';

import { RequestState } from '../../http/requestState';
import { Profile } from './ProfileModels';

export interface ProfileContextProps {
  profile?: Profile;
  requestGet: RequestState;
  getProfile: () => void;
}

export const ProfileContext = createContext<ProfileContextProps | undefined>(undefined);
