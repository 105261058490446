import { createContext } from 'react';

import { RequestState } from '../../http/requestState';
import {
  ClientCreateUser,
  CreateUser,
  Payer,
  PayerUser,
  User,
  AdminSearchResult,
} from './UsersModels';

export interface UsersContextProps {
  payer?: Payer;
  user?: User;
  clientUsers?: PayerUser[];
  clientUser?: User;
  requestAdminGetPayer: RequestState;
  requestAdminGetUser: RequestState;
  requestAdminCreateUser: RequestState;
  requestAdminUpdateClientAdmin: RequestState;
  requestAdminCreateClientAdmin: RequestState;
  requestAdminRemoveUser: RequestState;
  requestAdminResetPassword: RequestState;
  requestAdminEnableDisable: RequestState;

  requestClientGetUsers: RequestState;
  requestClientGetUser: RequestState;
  requestClientCreateUser: RequestState;
  requestClientUpdateUser: RequestState;
  requestClientResetPassword: RequestState;
  requestClientEnableDisable: RequestState;

  requestAdminSearch: RequestState;
  adminSearchResult?: AdminSearchResult[];

  adminGetPayer: (id: string) => void;
  adminGetUser: (id: string, email: string) => void;
  adminUpdateUser: (payload: CreateUser) => void;
  adminCreateUser: (id: string, payload: CreateUser) => void;
  adminRemoveUser: (id: string, email: string) => void;
  adminResetPassword: (email: string) => void;
  adminEnableDisableUser: (email: string, enable: boolean) => void;
  clearUser: () => void;
  adminSearch: (query: string) => void;

  clientGetUsers: () => void;
  clientGetUser: (email: string) => void;
  clientCreateUser: (payload: ClientCreateUser) => void;
  clientUpdateUser: (payload: ClientCreateUser) => void;
  clientResetPassword: (email: string) => void;
  clientEnableDisableUser: (email: string, enable: boolean) => void;
}

export const UsersContext = createContext<UsersContextProps | undefined>(undefined);
