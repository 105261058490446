import { createContext } from 'react';

import { RequestState } from '../../http/requestState';
import { GraphReport, GraphType, SavedGraphFilter, StatisticsGetRequest } from './GraphModels';

export interface GraphContextProps {
  savedFilters?: SavedGraphFilter[];
  graphReport?: GraphReport;
  graphReportCompare?: GraphReport;
  requestGetGraphReport: RequestState;
  requestGetGraphReportCompare: RequestState;
  requestGetSavedFilters: RequestState;
  requestCreateSavedFilters: RequestState;
  requestRemoveSavedFilter: RequestState;
  getGraphReport: (graph: GraphType, settings: StatisticsGetRequest, skipCache?: boolean) => void;
  getGraphReportCompare: (graph: GraphType, settings: StatisticsGetRequest) => void;
  getSavedFilters: () => void;
  createSavedFilter: (newSavedFilter: SavedGraphFilter) => void;
  removeSavedFilter: (id: string) => void;
}

export const GraphContext = createContext<GraphContextProps | undefined>(undefined);
