import React, { useMemo, useCallback, useReducer, useRef } from 'react';

import * as http from '../../http';
import {
  buildAuthorizedGetRequest,
  buildAuthorizedPostRequest,
  buildAuthorizedDeleteRequest,
} from '../../http/request-templates';
import { GraphContext } from './GraphContext';
import {
  GraphReport,
  StatisticsGetRequest,
  GraphGetRequestFilters,
  GraphCustomFiltersKeys,
  SavedGraphFilter,
  GraphType,
} from './GraphModels';

import graphReducer, {
  REQ_GET_GRAPH_REPORT,
  RCV_GET_GRAPH_REPORT,
  ERR_GET_GRAPH_REPORT,
  REQ_GET_GRAPH_REPORT_COMPARE,
  RCV_GET_GRAPH_REPORT_COMPARE,
  ERR_GET_GRAPH_REPORT_COMPARE,
  REQ_GET_SAVED_FILTERS,
  RCV_GET_SAVED_FILTERS,
  ERR_GET_SAVED_FILTERS,
  REQ_CREATE_SAVED_FILTERS,
  RCV_CREATE_SAVED_FILTERS,
  ERR_CREATE_SAVED_FILTERS,
  REQ_REMOVE_SAVED_FILTER,
  RCV_REMOVE_SAVED_FILTER,
  ERR_REMOVE_SAVED_FILTER,
  graphReducerInitialState,
} from './GraphReducer';
import useAuth from '../Auth/useAuth';
import { createGuid } from '../../Utils/guid';

const filtersToQueryParams = (filters: GraphGetRequestFilters | undefined) => {
  let s = '';
  Object.keys(filters || {}).forEach((key) => {
    if (filters?.[key as GraphCustomFiltersKeys]?.length) {
      s += `&${key}=${filters[key as GraphCustomFiltersKeys]?.join(',')}`;
    }
  });
  return s;
};

interface GraphProviderProps {
  children: React.ReactNode;
}

const GraphProvider = ({ children }: GraphProviderProps) => {
  const { token } = useAuth();
  const tokenRef = useRef(token);
  tokenRef.current = token;

  const [graphState, dispatch] = useReducer(graphReducer, graphReducerInitialState);

  const getGraphReport = useCallback(
    async (graph: GraphType, settings: StatisticsGetRequest, skipCache: boolean = false) => {
      if (!tokenRef.current) {
        throw new Error('Missing token');
      }

      try {
        dispatch({ type: REQ_GET_GRAPH_REPORT });
        let url = `${process.env.CUSTOMER_PORTAL_API}/reports/graphs/${graph}?date_from=${
          settings.date_from
        }&date_to=${settings.date_to}${filtersToQueryParams(settings.filters)}`;

        if (graph == 'co2-savings') {
          url = `${
            process.env.CUSTOMER_PORTAL_API
          }/reports/graphs/waste-by-month?value_field=co2saving&date_from=${
            settings.date_from
          }&date_to=${settings.date_to}${filtersToQueryParams(settings.filters)}`;
        }

        const response = await http.rawJson<GraphReport>(
          url,
          buildAuthorizedGetRequest(tokenRef.current),
          { cache: !skipCache }
        );

        dispatch({ type: RCV_GET_GRAPH_REPORT, graphReport: response });
      } catch (e) {
        dispatch({ type: ERR_GET_GRAPH_REPORT });
      }
    },
    []
  );

  const getGraphReportCompare = useCallback(
    async (graph: GraphType, settings: StatisticsGetRequest) => {
      if (!tokenRef.current) {
        throw new Error('Missing token');
      }

      try {
        dispatch({ type: REQ_GET_GRAPH_REPORT_COMPARE });
        let url = `${process.env.CUSTOMER_PORTAL_API}/reports/graphs/${graph}?date_from=${
          settings.date_from
        }&date_to=${settings.date_to}${filtersToQueryParams(settings.filters)}`;

        if (graph == 'co2-savings') {
          url = `${
            process.env.CUSTOMER_PORTAL_API
          }/reports/graphs/waste-by-month?value_field=co2saving&date_from=${
            settings.date_from
          }&date_to=${settings.date_to}${filtersToQueryParams(settings.filters)}`;
        }

        const response = await http.rawJson<GraphReport>(
          url,
          buildAuthorizedGetRequest(tokenRef.current),
          { cache: true }
        );

        dispatch({ type: RCV_GET_GRAPH_REPORT_COMPARE, graphReport: response });
      } catch (e) {
        dispatch({ type: ERR_GET_GRAPH_REPORT_COMPARE });
      }
    },
    []
  );

  const getSavedFilters = useCallback(async () => {
    if (!tokenRef.current) {
      throw new Error('Missing token');
    }

    try {
      dispatch({ type: REQ_GET_SAVED_FILTERS });
      const url = `${process.env.CUSTOMER_PORTAL_API}/templates/materials-by-month`;
      const response = await http.rawJson<SavedGraphFilter[]>(
        url,
        buildAuthorizedGetRequest(tokenRef.current)
      );
      dispatch({ type: RCV_GET_SAVED_FILTERS, savedFilters: response });
    } catch (e) {
      dispatch({ type: ERR_GET_SAVED_FILTERS });
    }
  }, []);

  const createSavedFilter = useCallback(async (newSavedFilter: SavedGraphFilter) => {
    if (!tokenRef.current) {
      throw new Error('Missing token');
    }

    try {
      dispatch({ type: REQ_CREATE_SAVED_FILTERS });
      const payload = { ...newSavedFilter, id: createGuid() };
      const url = `${process.env.CUSTOMER_PORTAL_API}/templates/materials-by-month`;
      await http.rawJson<SavedGraphFilter[]>(
        url,
        buildAuthorizedPostRequest(tokenRef.current, payload)
      );
      dispatch({ type: RCV_CREATE_SAVED_FILTERS, savedFilter: payload });
    } catch (e) {
      dispatch({ type: ERR_CREATE_SAVED_FILTERS });
    }
  }, []);

  const removeSavedFilter = useCallback(async (id: string) => {
    if (!tokenRef.current) {
      throw new Error('Missing token');
    }

    try {
      dispatch({ type: REQ_REMOVE_SAVED_FILTER });
      await http.rawJson<SavedGraphFilter[]>(
        `${process.env.CUSTOMER_PORTAL_API}/templates/materials-by-month/${id}`,
        buildAuthorizedDeleteRequest(tokenRef.current)
      );
      dispatch({ type: RCV_REMOVE_SAVED_FILTER, id });
    } catch (e) {
      dispatch({ type: ERR_REMOVE_SAVED_FILTER });
    }
  }, []);

  const value = useMemo(
    () => ({
      ...graphState,
      getGraphReport,
      getGraphReportCompare,
      getSavedFilters,
      createSavedFilter,
      removeSavedFilter,
    }),
    [graphState]
  );

  return <GraphContext.Provider value={value}>{children}</GraphContext.Provider>;
};

export default GraphProvider;
