import React from 'react';
import styled from 'styled-components';
import { size } from '../../../layout/helpers';
import { IconTree } from '../../Icon';

const Wrapper = styled.div`
  background-color: ${(props) => props.theme.color.bg.main};
  padding: ${size(2)};
  width: 100%;
`;

const Header = styled.div`
  color: ${(props) => props.theme.color.ui.signalPositive};
  display: flex;
  align-items: center;
  font-weight: 700;
  gap: 0.5em;
`;

const SubnavCo2Saved = () => {
  return (
    <Wrapper>
      <Header>
        <IconTree size="16px" $outlined />
        123 kg
      </Header>
      <span>Ert mijöbidrag, senaste månaden</span>
    </Wrapper>
  );
};

export default SubnavCo2Saved;
